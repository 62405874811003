import { Component, Inject } from '@angular/core';
import { AddDiscountPriceRequest } from './adddiscountpricerequest';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialog } from '@angular/material/dialog';
import { ApiRequestService, MAT_ICON, TOAST_STATE, ToastmessageService } from '@prekog/pw-common';
import { Router } from '@angular/router';
import { AddDiscountPriceResponse } from './adddiscountpriceresponse';
import { Material } from '../../../products/material';

@Component({
  selector: 'app-adddiscountpricepopup',
  templateUrl: './adddiscountpricepopup.component.html',
  styleUrls: ['./adddiscountpricepopup.component.scss']
})
export class AddDiscountpricepopupComponent {
    selectedAlign: 'start' | 'center' | 'end';

    storeBaseUrl = '';

    addDiscountPriceUrl = '/admin/add-discount-price';

    discountPriceForm?: FormGroup;

    addDiscountPriceRequest: AddDiscountPriceRequest = {};

    constructor(
        @Inject(MAT_DIALOG_DATA) public product: Material,
        private _dialog: MatDialog,
        @Inject('STORE_BACKEND_URL') private _storeBaseUrl: string,
        private _apiRequestService: ApiRequestService,
        private _router: Router,
        private _fb: FormBuilder,
        private _toastMessageService: ToastmessageService
    ) {
        this.selectedAlign = 'end';
        this.storeBaseUrl = _storeBaseUrl;
        this.createPPackageForm();
    }

    createPPackageForm() {
        this.discountPriceForm = this._fb.group({
            discountPrice: ['', [Validators.required]]
        });
    }

    addDiscountPrice() {
        this.addDiscountPriceRequest = {
            discountPrice: this.discountPriceForm?.controls['discountPrice'].value as number | undefined,
            productId: this.product.id
        };

        this._apiRequestService
            .sendRequest<AddDiscountPriceRequest, AddDiscountPriceResponse>(
                this.storeBaseUrl,
                true,
                this.addDiscountPriceRequest,
                true,
                this.addDiscountPriceUrl
            )
            .subscribe((response: AddDiscountPriceResponse) => {
                this._toastMessageService.displayToastMessage(TOAST_STATE.success, response.responseMessage!, MAT_ICON.success);
                this.dismissError();
                this._dialog.closeAll();
                window.location.reload();
                sessionStorage.setItem('product',JSON.stringify(this.product));
            });
    }

    private dismissError(): void {
        setTimeout(() => {
            this._toastMessageService.dismissToast();
        }, 3000);
    }

}
