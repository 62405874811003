<div class="banner-container">
    <div class="back-button">
        <mat-icon routerLink="/homeconfig">arrow_back</mat-icon>
    </div>
    <div class="banners">
        <div class="title-container">
            <div class="title">
                <h3>KEZDŐOLDAL ELEMEINEK RENDEZÉSE (DRAG AND DROP)</h3>
                <p>AKTÍV: MEGJELENIK A KEZDŐOLDALON</p>
                <span [ngStyle]="{ color: 'red' }">INAKTÍV: </span>
                <span>NEM JELENIK MEG A KEZDŐOLDALON</span>
            </div>
        </div>
        <div class="example-box" cdkDrag>
            <div cdkDropList class="example-list" (cdkDropListDropped)="drop($event)">
                <div
                    [ngStyle]="homeConfig.active ? { background: 'white' } : { background: '#efeff5' }"
                    class="example-box"
                    *ngFor="let homeConfig of homeConfigList; let i = index"
                    cdkDrag
                >
                    <div class="example">
                        <div class="order-number">
                            <span>{{ i + 1 }}</span>
                        </div>
                        <div class="title-banner">
                            <span>{{ homeConfig.name }}</span>
                        </div>

                        <div class="form-not-required">
                            <div class="field-container">
                                <div class="field">
                                    <div *ngIf="homeConfig.active">AKTÍV</div>
                                    <div [ngStyle]="{ color: 'red' }" *ngIf="!homeConfig.active">INAKTÍV</div>
                                    <div class="data">
                                        <mat-checkbox
                                            (click)="onChangeActive(homeConfig)"
                                            [ngModel]="homeConfig.active"
                                            id="active"
                                            class="form-control"
                                            type="checkbox"
                                            required
                                            [ngStyle]="{ 'font-weight': 'bold' }"
                                        ></mat-checkbox>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div [ngStyle]="{ 'margin-top': '8px' }" class="field-container">
        <div [ngStyle]="{ 'justify-content': 'center', 'margin-top': '8px', 'margin-bottom': '8px' }" class="field">
            <div [ngStyle]="{ 'justify-content': 'center' }" class="data">
                <button
                    (click)="updateHomeConfigOrder()"
                    [ngStyle]="{ background: '#e8fcfd', border: '1px solid #0E8388', 'font-weight': 'bold', width: '100%' }"
                >
                    Kezdőoldal rendezése
                </button>
            </div>
        </div>
    </div>
</div>
