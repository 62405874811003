import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialog } from '@angular/material/dialog';
import { OrderDetails } from '../order/order.model';
import { OrderStatus } from '../order/order-status.model';
import { ApiRequestService, MAT_ICON, TOAST_STATE, ToastmessageService } from '@prekog/pw-common';
import { GetOrdersResponse } from '../order/get-orders-response.model';


@Component({
  selector: 'app-confirm-status-popup',
  templateUrl: './confirm-status-popup.component.html',
  styleUrls: ['./confirm-status-popup.component.scss']
})
export class ConfirmStatusPopupComponent {
    selectedAlign: 'start' | 'center' | 'end';

    UPDATE_ORDER_STATUS_URI = '/admin/update-status';

    orderBackendUrl = '';



    constructor(
        @Inject(MAT_DIALOG_DATA) public order: OrderDetails,
        private _dialog: MatDialog,
        @Inject('ORDER_BACKEND_URL') private _orderBackendUrl: string,
        private _apiRequestService: ApiRequestService,
        private _toastMessageService: ToastmessageService
    ) {
        this.selectedAlign = 'end';
        this.orderBackendUrl = _orderBackendUrl;
    }


    closeDialog() {
        this.order.status = this.order.previousStatus;
        this._dialog.closeAll();


    }

    updateOrderStatus() {
        this._dialog.closeAll();
        const updateOrderStatusRequest: OrderStatus = new OrderStatus();
        updateOrderStatusRequest.orderDetailReference = this.order.orderDetailReference;
        updateOrderStatusRequest.status = this.order.status;
        this.order.previousStatus = this.order.status;

        this._apiRequestService
            .sendRequest<OrderStatus, GetOrdersResponse>(
                this.orderBackendUrl,
                true,
                updateOrderStatusRequest,
                true,
                this.UPDATE_ORDER_STATUS_URI
            )
            .subscribe(() => {
                this._toastMessageService.displayToastMessage(TOAST_STATE.success,updateOrderStatusRequest.orderDetailReference+' order updated!',MAT_ICON.success);
                this.dismissError();
                //after setting DELETED status refresh the window content
                //TODO if !separated és van customerOrderReference akkor filteredOrder-en maradjon window.reload()-nál
                if (this.order.status === 'DELETED' || this.order.customerOrderReference) {
                    //window.location.reload();
                }
            });
    }


    private dismissError(): void {
        setTimeout(() => {
            this._toastMessageService.dismissToast();
        }, 5000);
    }
}
