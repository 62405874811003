import { NgModule } from '@angular/core';
import { RouterModule, Routes, ExtraOptions } from '@angular/router';
import { CommonModule } from '@angular/common';
import { SigninComponent, BannersComponent, OrderBannersComponent, UpdatebannerComponent, AddbannerComponent } from '@prekog/pw-common';
import { OrderComponent } from './order/order.component';
import { RegisteredcustomersComponent } from './registeredcustomers/registeredcustomers.component';
import { PreordersComponent } from './preorders/preorders.component';
import { AddproductComponent } from './products/addproduct/addproduct.component';
import { ProductsComponent } from './products/products.component';
import { PackagesComponent } from './packages/packages.component';
import { UpdateproductComponent } from './products/updateproduct/updateproduct.component';
import { UpdatepackageComponent } from './packages/updatepackage/updatepackage.component';
import { SeriesComponent } from './series/series.component';
import { AddserieComponent } from './series/addserie/addserie.component';
import { UpdateserieComponent } from './series/updateserie/updateserie.component';
import { UpdatepackageproductsComponent } from './packages/updatepackageproducts/updatepackageproducts.component';
import { AddproductstoserieComponent } from './series/addproductstoserie/addproductstoserie.component';
import { OnsaleperiodsComponent } from './onsaleperiods/onsaleperiods.component';
import { UpdateonsaleComponent } from './onsaleperiods/updateonsale/updateonsale.component';
import { HomeConfigComponent } from './home-config/home-config.component';
import { OrderHomeConfigComponent } from './home-config/order-home-config/order-home-config.component';
import { UpdateHomeConfigComponent } from './home-config/update-home-config/update-home-config.component';
import { AddpackageComponent } from './packages/addpackage/addpackage.component';
import { AddproductstopackageComponent } from './packages/addproductstopackage/addproductstopackage.component';
import { ProductsaleComponent } from './onsaleperiods/addproductsonsale/addproductsonsale.component';
import { GetordersComponent } from './order/getorders/getorders.component';
import { GetorderdetailsbyorderidComponent } from './order/getorderdetailsbyorderid/getorderdetailsbyorderid.component';
import { AddHomeInfoComponent } from './home-config/home-info/add-home-info/add-home-info.component';
import { UpdateHomeInfoComponent } from './home-config/home-info/update-home-info/update-home-info.component';



const routes: Routes = [
    { path: '', component: SigninComponent },
    { path: 'order', component: OrderComponent },
    { path: 'getorders', component: GetordersComponent },
    { path: 'getorders/:id', component: GetorderdetailsbyorderidComponent },
    { path: 'registeredcustomers', component: RegisteredcustomersComponent },
    { path: 'preorder', component: PreordersComponent },
    { path: 'addproduct', component: AddproductComponent },
    { path: 'products', component: ProductsComponent },
    { path: 'packages', component: PackagesComponent },
    { path: 'packages/:routerLink', component: UpdatepackageComponent },
    { path: 'products/:routerLink', component: UpdateproductComponent },
    { path: 'addpackage', component: AddpackageComponent },
    { path: 'addpackagetoproduct', component: AddproductstopackageComponent },
    { path: 'series', component: SeriesComponent },
    { path: 'series/:routerLink', component: UpdateserieComponent },
    { path: 'addserie', component: AddserieComponent },
    { path: 'updateproductsforpackage', component: UpdatepackageproductsComponent },
    { path: 'addserie/:id', component: AddproductstoserieComponent },
    { path: 'addproductsale', component: ProductsaleComponent },
    { path: 'onsaleperiod', component: OnsaleperiodsComponent },
    { path: 'onsaleperiod/:routerLink', component: UpdateonsaleComponent },
    { path: 'orderbanners', component: OrderBannersComponent },
    { path: 'banners', component: BannersComponent },
    { path: 'banners/:id', component: UpdatebannerComponent },
    { path: 'addbanner', component: AddbannerComponent },
    { path: 'homeconfig', component: HomeConfigComponent },
    { path: 'orderhomeconfig', component: OrderHomeConfigComponent },
    { path: 'homeconfig/:component', component: UpdateHomeConfigComponent },
    { path: 'updatehomeinfo', component: UpdateHomeInfoComponent }


];
const routeroptions : ExtraOptions = {
    scrollPositionRestoration:'enabled',
    anchorScrolling:'enabled'
}

@NgModule({
    imports: [RouterModule.forRoot(routes,routeroptions), CommonModule],
    exports: [RouterModule]
})
export class AppRoutingModule {}
