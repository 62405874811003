<div class="create-package-main-container">
    <div class="title"><h3>CSOMAG ÖSSZEÁLLÍTÁSA</h3></div>
    <div class="drag-and-drop-container">
        <div class="drag-and-drop">
            <div class="example-container">
                <h2>Termékek</h2>
                <div
                    cdkDropList
                    #todoList="cdkDropList"
                    [cdkDropListData]="products"
                    [cdkDropListConnectedTo]="[doneList]"
                    class="example-list"
                    (cdkDropListDropped)="drop($event)"
                >
                    <div class="example-box" *ngFor="let product of products" cdkDrag>{{ product.author + ': ' + product.title }}</div>
                </div>
            </div>
            <div class="example-container">
                <h2>{{ package.packageName }}</h2>
                <div
                    cdkDropList
                    #doneList="cdkDropList"
                    [cdkDropListData]="productsToPackage"
                    [cdkDropListConnectedTo]="[todoList]"
                    class="example-list"
                    (cdkDropListDropped)="drop($event)"
                >
                    <div class="example-box" *ngFor="let item of productsToPackage" cdkDrag>{{ item.author + ':' + item.title }}</div>
                </div>
            </div>
        </div>
    </div>
    <div [ngStyle]="{ 'margin-top': '8px' }" class="field-container">
        <div [ngStyle]="{ 'justify-content': 'center', 'margin-top': '8px', 'margin-bottom': '8px' }" class="field">
            <div [ngStyle]="{ 'justify-content': 'center' }" class="data">
                <button
                    (click)="createPackageProduct(productsToPackage)"
                    [ngStyle]="{ background: '#e8fcfd', border: '1px solid #0E8388', 'font-weight': 'bold', width: '100%' }"
                >
                    CSOMAG MENTÉSE
                </button>
            </div>
        </div>
    </div>
</div>
