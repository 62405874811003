
export const environment = {
    production: false,
    backendOrderUrl: 'https://order.test.multiverzumkiado.hu',
    backendEmailUrl: 'https://email.test.multiverzumkiado.hu',
    backendStoreUrl: 'https://store.test.multiverzumkiado.hu',
    backendAuthUrl: 'https://auth.test.multiverzumkiado.hu',
    backendFileManagerUrl: 'https://file.test.multiverzumkiado.hu',
    backendConfiguratorUrl : 'https://configurator.test.multiverzumkiado.hu',
    apiUrl: 'https://test.multiverzumkiado.hu/',
    downloadUrl:'https://file.test.multiverzumkiado.hu/file/downloadFile'


};
