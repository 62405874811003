import { OrderProduct } from './order-product.model';
import { Product } from './product.model';

export class OrderExportToExcel {
    constructor(
        public id?: number,

        public customerAddress?: string,

        public customerEmail?: string,

        public customerSettlement?: string,

        public customerZipCode?: number,

        public customerPhone?: string,

        public companyName?: string,

        public firstName?: string,

        public lastName?: string,

        public  deliveryAddress?: string,

        public  deliveryOption?: string,

        public deliverySettlement?: string,

        public  deliveryZipCode?: string,

        public orderReference?: string,

        public paymentOption?: string,

        public  status?: string,

        public taxNumber?: string,

        public  totalPrice?: number,

        public insertedTimestamp?: string,

        public titles?:string[]
    ) {}


}
