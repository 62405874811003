<div class="series">
    <div class="title">
        <div class="tit">
            <h3>SOROZATOK</h3>
            <div class="add-icon">
                <mat-icon routerLink="/addserie">add_circle</mat-icon>
            </div>
        </div>
    </div>
    <div class="add-new-series">
        <div class="search-bar">
            <div class="search-tool">
                <input [(ngModel)]="listFilter" type="search" placeholder="Sorozat neve" />
                <div class="icon">
                    <mat-icon aria-hidden="false" aria-label="keyboard_arrow_right icon" fontIcon="search"></mat-icon>
                </div>
            </div>
        </div>
        <!-- <button routerLink="/addserie">ÚJ SOROZAT HOZZÁADÁSA</button> -->
    </div>
    <div *ngFor="let serie of filteredSeries; let i = index" class="serie">
        <div class="pro">
            <div class="name">
                <div
                    [ngStyle]="i % 2 === 0 ? { background: 'white' } : { background: '#efeff5' }"
                    class="button"
                    routerLink="{{ serie.routerLink }}"
                >
                    {{ serie.serieName }}
                </div>
            </div>
        </div>
    </div>
</div>
