import { Component } from '@angular/core';

@Component({
  selector: 'pw-add-home-info',
  templateUrl: './add-home-info.component.html',
  styleUrls: ['./add-home-info.component.scss']
})
export class AddHomeInfoComponent {

}
