import { Component, ElementRef, Inject, OnInit, ViewChild } from '@angular/core';
import { FindHomeConfigRequest } from './findhomeconfigrequest';
import { FindHomeConfigResponse } from './findhomeconfigresponse';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { ApiRequestService, HttpError, MAT_ICON, TOAST_STATE, ToastmessageService, TranslationService } from '@prekog/pw-common';
import { HomeConfig } from '../home-config';
import { catchError, throwError } from 'rxjs';
import { HttpErrorResponse } from '@angular/common/http';
import { UpdateHomeConfigRequest } from './updatehomeconfigrequest';
import { UpdateHomeConfigResponse } from './updatehomeconfigresponse';

@Component({
    selector: 'app-update-home-config',
    templateUrl: './update-home-config.component.html',
    styleUrls: ['./update-home-config.component.scss']
})
export class UpdateHomeConfigComponent implements OnInit {
    updateHomeConfigUrl = '/admin/update-homeconfig';

    configuratorBaseUrl = '';

    findHomeConfigUrl = '/admin/find-homeconfig';

    homeConfig:HomeConfig = {};

    homeConfigToUpdate:HomeConfig = {};

    homeConfigForm?: FormGroup;

    @ViewChild('activeInput') activeInput!: ElementRef;
    active = false;

    constructor(
        private _toastMessageService: ToastmessageService,
        private _apiRequestService: ApiRequestService,
        @Inject('CONFIGURATOR_BASE_URL') private _configuratorBaseUrl: string,
        private _fb: FormBuilder,
        private _router:Router,
        private _translateService:TranslationService
    ) {
        this.configuratorBaseUrl = _configuratorBaseUrl;
        this.createHomeConfigForm();
    }

    ngOnInit(): void {
        if (sessionStorage.getItem('token')) {
        const lastSegmentOfUrl = window.location.href.split('/').pop();


        const findHomeConfigRequest: FindHomeConfigRequest = {
            component: lastSegmentOfUrl
        };

        this._apiRequestService
        .sendRequest<FindHomeConfigRequest, FindHomeConfigResponse>(
            this.configuratorBaseUrl,
            true,
            findHomeConfigRequest,
            true,
            this.findHomeConfigUrl
        )
        .pipe(
            catchError((error: HttpErrorResponse) => {
                return throwError(() => {
                    const httpError: HttpError = error.error as HttpError;

                    this._toastMessageService.displayToastMessage(
                        TOAST_STATE.error,
                        'Sikertelen művelet' + httpError.errorMessage,
                        MAT_ICON.error
                    );
                    this.dismissError();
                });
            })
        )
        .subscribe((response) => {
            this.homeConfig = response.homeConfig as HomeConfig;
            this.active = this.homeConfig.active as boolean;

        });
    }
    else {
        this._router.navigateByUrl('/');
    }

    }

    private dismissError(): void {
        setTimeout(() => {
            this._toastMessageService.dismissToast();
        }, 3000);
    }

    createHomeConfigForm() {
        this.homeConfigForm = this._fb.group({
            name: ['', [Validators.required]]
        });
    }

    collectHomeConfigData() {
        this.homeConfigToUpdate = {
            name: this.homeConfigForm?.controls['name'].value as string | undefined,
            active: this.active,
            id: this.homeConfig.id,
            component:this.homeConfig.component

        };
    }

    updateHomeConfig() {
        this.collectHomeConfigData();
        const updateHomeConfigRequest:UpdateHomeConfigRequest = {
            homeConfig:this.homeConfigToUpdate
        }
        this._apiRequestService
            .sendRequest<UpdateHomeConfigRequest, UpdateHomeConfigResponse>(this.configuratorBaseUrl, true, updateHomeConfigRequest, true, this.updateHomeConfigUrl)
            .pipe(
                catchError((error: HttpErrorResponse) => {
                    return throwError(() => {
                        const httpError: HttpError = error.error as HttpError;

                            this._toastMessageService.displayToastMessage(TOAST_STATE.error, this._translateService.translate('admin.unsuccessfulSave! '+httpError.errorMessage), MAT_ICON.error);
                            this.dismissError();

                        this._router.navigateByUrl('/');
                    });
                })
            )
            .subscribe((response) => {
                this._toastMessageService.displayToastMessage(
                    TOAST_STATE.success,
                    response.responseMessage as string,
                    MAT_ICON.success
                );
                this.dismissError();
            });
    }

    onChangeActive() {
        this.active = !this.active;
    }
}
