import { Component, Inject, OnInit } from '@angular/core';
import { BannerConfig } from '../orderbanners/bannerconfig';
import { ApiRequestService, ToastmessageService, HttpError, TOAST_STATE, MAT_ICON } from '@prekog/pw-common';
import { DeleteBannerRequest } from '../orderbanners/deletebannerrequest';
import { DeleteBannerResponse } from '../orderbanners/deletebannerresponse';
import { catchError, throwError } from 'rxjs';
import { HttpErrorResponse } from '@angular/common/http';
import { MAT_DIALOG_DATA, MatDialog } from '@angular/material/dialog';

@Component({
  selector: 'app-deletebannerpopup',
  templateUrl: './deletebannerpopup.component.html',
  styleUrls: ['./deletebannerpopup.component.scss']
})
export class DeletebannerpopupComponent {

    deleteBannerUrl = '/admin/delete-banner';

    configuratorBaseUrl = '';

    selectedAlign: 'start' | 'center' | 'end';

    constructor(
        private _toastMessageService: ToastmessageService,
        private _apiRequestService: ApiRequestService,
        @Inject('CONFIGURATOR_BASE_URL') private _configuratorBaseUrl: string,
        private _matdialog:MatDialog,
        @Inject(MAT_DIALOG_DATA) public banner: BannerConfig
    ) {
        this.configuratorBaseUrl = _configuratorBaseUrl;

        this.selectedAlign = 'end';
    }

      //TODO confirm popup!!!
      deleteBanner() {
        const deleteBannerRequest: DeleteBannerRequest = {
            orderNumber: this.banner.orderNumber
        };

        this._apiRequestService
            .sendRequest<DeleteBannerRequest, DeleteBannerResponse>(
                this.configuratorBaseUrl,
                true,
                deleteBannerRequest,
                true,
                this.deleteBannerUrl
            )
            .pipe(
                catchError((error: HttpErrorResponse) => {
                    return throwError(() => {
                        const httpError: HttpError = error.error as HttpError;

                        this._toastMessageService.displayToastMessage(
                            TOAST_STATE.error,
                            'Sikertelen törlés' + httpError.errorMessage,
                            MAT_ICON.error
                        );
                        this.dismissError();
                    });
                })
            )
            .subscribe((response) => {
                this._toastMessageService.displayToastMessage(TOAST_STATE.success, response.responseMessage!, MAT_ICON.success);
                this.dismissError();
                this.closeDialog();
                window.location.reload();
            });
    }

    private dismissError(): void {
        setTimeout(() => {
            this._toastMessageService.dismissToast();
        }, 3000);
    }
    closeDialog() {
        this._matdialog.closeAll();
    }

}
