import { Component, Input, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { SigninComponent, TranslatableComponent } from '@prekog/pw-common';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { TranslationStore } from '@prekog/pw-common';
import { TranslationService } from '@prekog/pw-common';
import { GetTranslationRequest } from './usecases/translation-usecase/read-translation-request';
import { GetTranslationUseCase } from './usecases/translation-usecase/read-translation-usecase';

@Component({
    selector: 'pw-root',
    templateUrl: './app.component.html',
    styleUrls: ['./app.component.scss']
})
export class AppComponent extends TranslatableComponent {
    title = 'Multiverzum Admin';
    headerTitle = 'Multiverzum Admin';
    logoUrl = '../../assets/images/logo.png';
    hasLanguageSelector = false;

    hasLogo = false;

    hasSearchBar = false;

    facebookURL = '';

    facebookImgUrl = '';

    hasShoppingCart = false;

    hasLogin = false;

    hasAdmin = true;

    colorOfNavBar = 'black';

    hasAdminLogo = false;

    nameOfTheSite = '';

    positionL = 'PositionL';

    adminHomeUrl = '/getorders';

    override readTranslations(http: HttpClient, language: string): Observable<TranslationStore> {
        const request: GetTranslationRequest = { url: './assets/i18n/' + language + '.json' };
        this._readTranslationfileUseCase.execute(request) as unknown as Observable<TranslationStore>;
        return this._http.get<TranslationStore>('./assets/i18n/' + language + '.json');
    }
    override onInit(): void {
        if (!sessionStorage.getItem('token')) {
            this._router.navigateByUrl('/');
        }
    }
    constructor(
        public _router: Router,
        private _translateService: TranslationService,
        private _readTranslationfileUseCase: GetTranslationUseCase,
        private _http: HttpClient
    ) {
        super(_translateService, _http);
    }

    //Router outlet @Input load
    loadRouterOutletInput(component: SigninComponent) {
        if (component instanceof SigninComponent) {
            component.admin = true;
            component.hasCityAndZipUrl = false;
            component.redirectUrl = '/getorders'
        }
    }
}
