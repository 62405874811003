<div class="contact-content">
    <div class="back-button">
        <mat-icon routerLink="/banners">arrow_back</mat-icon>
    </div>
    <div>
        <h3>BANNER MÓDOSÍTÁSA</h3>
    </div>
    <div class="contact-form">
        <form [formGroup]="bannerForm!" novalidate>
            <div class="field-container">
                <div class="field">
                    <span>Banner neve</span>
                    <div class="data">
                        <input
                            [(ngModel)]="bannerConfig.title"
                            formControlName="title"
                            id="title"
                            class="form-control"
                            type="text"
                            required
                        />
                    </div>
                </div>
            </div>
            <div class="field-container">
                <div class="field">
                    <span>Banner linkje</span>
                    <div class="data">
                        <input [(ngModel)]="bannerConfig.link" formControlName="link" id="link" class="form-control" type="text" required />
                    </div>
                </div>
            </div>
            <div class="field-container">
                <div class="field">
                    <span>Kép (.jpg, maximum méret: 5MB)</span>
                    <div class="data">
                        <div>
                            <button [ngStyle]="{ background: 'lightgrey' }" onclick="document.getElementById('fileCover').click()">
                                Kép kiválasztása
                            </button>
                            <span *ngIf="updateCoverFile">{{ fileCover?.name }}</span>
                            <a href="{{ bannerConfig.imageUrl }}" *ngIf="!updateCoverFile" target="_blank">Jelenlegi Kép</a>
                            <mat-icon (click)="clearSelectedCoverFile()">delete_forever</mat-icon>
                            <input
                                [ngStyle]="{ display: 'none' }"
                                id="fileCover"
                                #fileCoverInput
                                class="form-control"
                                type="file"
                                (change)="onChange($event)"
                            />
                        </div>
                    </div>
                </div>
            </div>
        </form>
    </div>
    <div [ngStyle]="{ 'margin-top': '8px' }" class="field-container">
        <div [ngStyle]="{ 'justify-content': 'center', 'margin-top': '8px', 'margin-bottom': '8px' }" class="field">
            <div [ngStyle]="{ 'justify-content': 'center' }" class="data">
                <button
                    (click)="updateBanner(bannerConfig)"
                    [ngStyle]="{ background: '#e8fcfd', border: '1px solid #0E8388', 'font-weight': 'bold', width: '100%' }"
                    [disabled]="!bannerForm?.valid"
                >
                    BANNER MÓDOSÍTÁSA
                </button>
            </div>
        </div>
    </div>
</div>
