<div class="contact-content">
    <div class="back-button">
        <mat-icon routerLink="/products">arrow_back</mat-icon>
    </div>
    <div [ngStyle]="{ display: 'flex', width: '100%', 'align-items': 'center', 'flex-direction': 'column', 'margin-bottom': '24px' }">
        <h3>TERMÉK MÓDOSÍTÁSA</h3>
        <b [ngStyle]="{ color: 'red' }" *ngIf="product.quantityOfSupply === 0">ELFOGYOTT</b>
    </div>
    <div class="preorder" *ngIf="product.hasPreorder">
        <h3>ELŐRENDELÉS</h3>
        <button (click)="setPreorderPublished()">Előrendelhető könyv megjelenése</button>
    </div>
    <div class="contact-form">
        <form [formGroup]="productForm!" novalidate>
            <div class="field-container">
                <div class="field">
                    <span>Szerző<span class="star">*</span></span>
                    <div class="data">
                        <input
                            [(ngModel)]="product.author"
                            id="author"
                            formControlName="author"
                            class="form-control"
                            type="text"
                            required
                        />
                        <div
                            *ngIf="
                                productForm!.controls['author'].invalid &&
                                (productForm!.controls['author'].dirty || productForm!.controls['author'].touched)
                            "
                            class="alert"
                        >
                            <div *ngIf="productForm!.controls['author'].errors?.['required']">
                                <span>{{ 'admin.authorRequired' | translate }}</span>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="field-container">
                <div class="field">
                    <span>Cím<span class="star">*</span></span>
                    <div class="data">
                        <input [(ngModel)]="product.title" id="title" formControlName="title" class="form-control" type="text" required />
                        <div
                            *ngIf="
                                productForm!.controls['title'].invalid &&
                                (productForm!.controls['title'].dirty || productForm!.controls['title'].touched)
                            "
                            class="alert"
                        >
                            <div *ngIf="productForm!.controls['title'].errors?.['required']">
                                <span>{{ 'admin.titleRequired' | translate }}</span>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="field-container">
                <div class="field">
                    <span>Könyvön szereplő ár<span class="star">*</span></span>
                    <span
                        [ngStyle]="{ color: 'red', 'font-size': '12px' }"
                        class="text-danger"
                        *ngIf="productForm!.get('originalPrice')!.hasError('pattern')"
                    >
                        Az ár csak számot tartalmazhat!
                    </span>
                    <div class="data">
                        <input
                            [(ngModel)]="product.originalPrice"
                            id="originalPrice"
                            formControlName="originalPrice"
                            class="form-control"
                            type="number"
                            required
                        />
                        <div
                            *ngIf="
                                productForm!.controls['originalPrice'].invalid &&
                                (productForm!.controls['originalPrice'].dirty || productForm!.controls['originalPrice'].touched)
                            "
                            class="alert"
                        >
                            <div *ngIf="productForm!.controls['originalPrice'].errors?.['required']">
                                <span>{{ 'admin.originalPriceRequired' | translate }}</span>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="field-container">
                <div class="field">
                    <span>Oldalszám<span class="star">*</span></span>
                    <div class="data">
                        <input
                            [(ngModel)]="product.content!"
                            formControlName="content"
                            id="content"
                            class="form-control"
                            type="text"
                            required
                        />
                        <div
                            *ngIf="
                                productForm!.controls['content'].invalid &&
                                (productForm!.controls['content'].dirty || productForm!.controls['content'].touched)
                            "
                            class="alert"
                        >
                            <div *ngIf="productForm!.controls['content'].errors?.['required']">
                                <span>{{ 'admin.contentRequired' | translate }}</span>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="field-container">
                <div class="field">
                    <span>ISBN<span class="star">*</span></span>
                    <div class="data">
                        <input [(ngModel)]="product.isbn" id="isbn" formControlName="isbn" class="form-control" type="number" required />
                        <div
                            *ngIf="
                                productForm!.controls['isbn'].invalid &&
                                (productForm!.controls['isbn'].dirty || productForm!.controls['isbn'].touched)
                            "
                            class="alert"
                        >
                            <div *ngIf="productForm!.controls['isbn'].errors?.['required']">
                                <span>{{ 'admin.isbnRequired' | translate }}</span>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="field-container">
                <div class="field">
                    <span>Megjelenési dátum<span class="star">*</span></span>
                    <div class="data">
                        <input
                            [ngModel]="product.available | date : 'yyyy-MM-dd'"
                            (ngModel)="product.available = $any($event).target.valueAsDate"
                            formControlName="available"
                            (change)="onChangeAvailable($event)"
                            id="available"
                            class="form-control"
                            type="date"
                            placeholder="YYYY-MM-DD"
                            pattern="[0-9]{4}-[0-9]{2}-[0-9]{2}"
                            required
                        />
                    </div>
                </div>
            </div>
            <div class="field-container">
                <div class="field">
                    <span>Kötés<span class="star">*</span></span>
                    <div class="data">
                        <input [(ngModel)]="product.bond" id="bond" formControlName="bond" class="form-control" type="text" required />
                        <div
                            *ngIf="
                                productForm!.controls['bond'].invalid &&
                                (productForm!.controls['bond'].dirty || productForm!.controls['bond'].touched)
                            "
                            class="alert"
                        >
                            <div *ngIf="productForm!.controls['bond'].errors?.['required']">
                                <span>{{ 'admin.bondRequired' | translate }}</span>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div class="field-container">
                <div class="field">
                    <span>Méret<span class="star">*</span></span>
                    <div class="data">
                        <input
                            [(ngModel)]="product.dimension"
                            id="dimension"
                            formControlName="dimension"
                            class="form-control"
                            type="text"
                            required
                        />
                        <div
                            *ngIf="
                                productForm!.controls['dimension'].invalid &&
                                (productForm!.controls['dimension'].dirty || productForm!.controls['dimension'].touched)
                            "
                            class="alert"
                        >
                            <div *ngIf="productForm!.controls['dimension'].errors?.['required']">
                                <span>{{ 'admin.dimensionRequired' | translate }}</span>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="field-container">
                <div class="field">
                    <span>Készlet<span class="star">*</span></span>
                    <div class="data">
                        <input
                            [(ngModel)]="product.quantityOfSupply"
                            id="quantityofsupply"
                            formControlName="quantityofsupply"
                            class="form-control"
                            type="number"
                            required
                        />
                        <div
                            *ngIf="
                                productForm!.controls['quantityofsupply'].invalid &&
                                (productForm!.controls['quantityofsupply'].dirty || productForm!.controls['quantityofsupply'].touched)
                            "
                            class="alert"
                        >
                            <div *ngIf="productForm!.controls['quantityofsupply'].errors?.['required']">
                                <span>{{ 'admin.quantityOfSupplyRequired' | translate }}</span>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </form>
        <div class="form-not-required">
            <div *ngIf="!product.hasPreorder" class="field-container">
                <div class="field">
                    <span>Megjelent?</span>
                    <div class="data">
                        <mat-checkbox
                            (change)="onChangeIsAvailable()"
                            #isAvailableInput
                            [ngModel]="isAvailable"
                            id="isAvailable"
                            class="form-control"
                            type="checkbox"
                            required
                            [ngStyle]="{ 'font-weight': 'bold' }"
                            [disabled]="hasPreorder"
                        ></mat-checkbox>
                    </div>
                </div>
            </div>
            <div class="field-container">
                <div class="field">
                    <span>Online ár</span>
                    <div class="data">
                        <input
                            (change)="onChangeWebshopPrice()"
                            [(ngModel)]="webshopPrice"
                            #webshopPriceInput
                            id="webshopPrice"
                            class="form-control"
                            type="number"
                            required
                        />
                        <span *ngIf="!product.hasPreorder">Aktív?</span>
                        <div *ngIf="!product.hasPreorder" class="data">
                            <mat-checkbox
                                [ngModel]="webshopPriceActive"
                                (change)="onChangeWebshopPriceActive()"
                                #webshopPriceActiveInput
                                id="webshopPriceActive"
                                class="form-control"
                                type="checkbox"
                                required
                            ></mat-checkbox>
                        </div>
                    </div>
                </div>
            </div>
            <div class="field-container">
                <div class="field">
                    <span>Előrendelői ár</span>
                    <div class="data">
                        <input
                            [ngModel]="preorderPrice"
                            (change)="onChangePreorderPrice()"
                            #preorderPriceInput
                            id="preorderPrice"
                            class="form-control"
                            type="number"
                            required
                        />
                        <span>Aktív?</span>
                        <div *ngIf="product.hasPreorder" class="data">
                            <mat-checkbox
                                [ngModel]="hasPreorder"
                                (change)="onChangePreorderPriceActive()"
                                #hasPreorderInput
                                id="hasPreorder"
                                class="form-control"
                                type="checkbox"
                                required
                                disabled
                            ></mat-checkbox>
                        </div>
                        <div *ngIf="!product.hasPreorder" class="data">
                            <mat-checkbox
                                [ngModel]="hasPreorder"
                                (change)="onChangePreorderPriceActive()"
                                #hasPreorderInput
                                id="hasPreorder"
                                class="form-control"
                                type="checkbox"
                                required
                            ></mat-checkbox>
                        </div>
                    </div>
                </div>
            </div>

            <div class="field-container">
                <div class="field">
                    <span>Akciós ár</span>
                    <div class="data">
                        <input
                            [ngModel]="discountPrice"
                            (change)="onChangeDiscountPrice()"
                            #discountPriceInput
                            id="discountPrice"
                            class="form-control"
                            type="number"
                            required
                        />
                        <span *ngIf="!product.hasPreorder">Aktív?</span>
                        <div *ngIf="!product.hasPreorder" class="data">
                            <mat-checkbox
                                [ngModel]="discountPriceActive"
                                (change)="onChangeDiscountPriceActive()"
                                #discountPriceActiveInput
                                id="discountPriceActive"
                                class="form-control"
                                type="checkbox"
                                required
                            ></mat-checkbox>
                        </div>
                    </div>
                </div>
            </div>
            <div class="field-container">
                <div class="field">
                    <span>Csomagár</span>
                    <div class="data">
                        <input
                            [ngModel]="packagePrice"
                            (change)="onChangePackagePrice()"
                            #packagePriceInput
                            id="packagePrice"
                            class="form-control"
                            type="number"
                            required
                        />
                    </div>
                </div>
            </div>

            <div class="field-container">
                <div class="field">
                    <span>Fordító</span>
                    <div class="data">
                        <input
                            [ngModel]="translator"
                            (change)="onChangeTranslator()"
                            #translatorInput
                            id="translator"
                            class="form-control"
                            type="text"
                            required
                        />
                    </div>
                </div>
            </div>

            <div class="field-container">
                <div class="field">
                    <span>Illusztrátor</span>
                    <div class="data">
                        <input
                            [ngModel]="illustrator"
                            (change)="onChangeIllustrator()"
                            #illustratorInput
                            id="illustrator"
                            class="form-control"
                            type="text"
                            required
                        />
                    </div>
                </div>
            </div>
            <div class="field-container">
                <div class="field">
                    <span>Alcím</span>
                    <div class="data">
                        <input
                            [ngModel]="subtitle"
                            (change)="onChangeSubtitle()"
                            #subtitleInput
                            id="subtitle"
                            class="form-control"
                            type="text"
                            required
                        />
                    </div>
                </div>
            </div>
            <div class="field-container">
                <div class="field">
                    <span>Fülszöveg *</span>
                    <div class="data">
                        <pw-htmleditor
                            [html]="product.txt!"
                            [htmlForm]="htmlForm"
                            class="form-control"
                            type="text"
                            required
                            ngDefaultControl
                        ></pw-htmleditor>
                    </div>
                </div>
            </div>
            <div class="field-container">
                <div class="field">
                    <span>Borítókép (.png, maximum méret: 5MB) </span>
                    <div class="data">
                        <div>
                            <button [ngStyle]="{ background: 'lightgrey' }" onclick="document.getElementById('file').click()">
                                Borítókép kiválasztása
                            </button>
                            <span *ngIf="updateCoverFile">{{ fileCover?.name }}</span>
                            <a href="{{ product.url }}" *ngIf="!updateCoverFile" target="_blank">Jelenlegi borítókép</a>
                            <mat-icon (click)="clearSelectedCoverFile()">delete_forever</mat-icon>
                            <input
                                [ngStyle]="{ display: 'none' }"
                                id="file"
                                #fileCoverInput
                                class="form-control"
                                type="file"
                                (change)="onChangeCover($event)"
                            />
                        </div>
                    </div>
                </div>
            </div>
            <div class="field-container">
                <div class="field">
                    <span>Beleolvasó (.pdf)</span>
                    <div class="data">
                        <div>
                            <button [ngStyle]="{ background: 'lightgrey' }" onclick="document.getElementById('fileBookExtract').click()">
                                Beleolvasó kiválasztása
                            </button>
                            <span *ngIf="updateBookExtractFile">{{ fileBookExtract?.name }}</span>
                            <span *ngIf="!product.bookExtractUrl && !updateBookExtractFile">Nincs beleolvasó kiválasztva</span>
                            <a href="{{ product.bookExtractUrl }}" *ngIf="product.bookExtractUrl && !updateBookExtractFile" target="_blank"
                                >Jelenlegi beleolvasó</a
                            >
                            <mat-icon (click)="clearSelectedBookExtractFile()">delete_forever</mat-icon>
                            <input
                                [ngStyle]="{ display: 'none' }"
                                id="fileBookExtract"
                                #fileBookExtractInput
                                class="form-control"
                                type="file"
                                (change)="onChangeBookExtract($event)"
                            />
                        </div>
                    </div>
                </div>
            </div>
            <div [ngStyle]="{ 'margin-top': '8px', 'border-bottom': '2px solid black' }" class="field-container-other">
                <div [ngStyle]="{ 'justify-content': 'center', 'margin-top': '8px', 'margin-bottom': '8px' }" class="field-other">
                    <div [ngStyle]="{ 'justify-content': 'center' }" class="data-other">
                        <button
                            (click)="updateProductData(product)"
                            [ngStyle]="{
                                background: '#e8fcfd',
                                border: '1px solid #0E8388',
                                'font-weight': 'bold',
                                width: '100%'
                            }"
                            [disabled]="!productForm?.valid || !htmlForm?.valid"
                        >
                            ADATOK MÓDOSÍTÁSA
                        </button>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
