<div class="banners">
    <div class="title">
        <div class="tit">
            <h3>KEZDŐOLDAL ELEMEI</h3>
            <div class="add-icon">
                <button routerLink="/orderhomeconfig">Kezdőoldal elemeinek rendezése</button>
            </div>
            <div class="add-home-info">
                <button routerLink="/updatehomeinfo">Információs szöveg szerkesztése</button>
            </div>
        </div>
    </div>
    <div *ngFor="let homeConfig of homeConfigList; let i = index" class="banner">
        <div class="pro">
            <div [ngStyle]="i % 2 === 0 ? { background: 'white' } : { background: '#efeff5' }" class="name">
                <div class="button" routerLink="{{ homeConfig.component }}">{{ homeConfig.name }}</div>
            </div>
        </div>
    </div>
</div>
