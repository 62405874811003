import { Component, Inject, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { ApiRequestService, HttpError, MAT_ICON, TOAST_STATE, ToastmessageService, TranslationService } from '@prekog/pw-common';
import { HomeInfo } from './home-info';
import { HomeInfoResponse } from './homeinforesponse';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { UpdateHomeInfoRequest } from './updatehomeinforequest';
import { UpdateHomeInfoResponse } from './updatehomeinforesponse';
import { catchError, throwError } from 'rxjs';
import { HttpErrorResponse } from '@angular/common/http';

@Component({
    selector: 'app-update-home-info',
    templateUrl: './update-home-info.component.html',
    styleUrls: ['./update-home-info.component.scss']
})
export class UpdateHomeInfoComponent implements OnInit {
    getHomeInfoUrl = '/websiteconfigurator/get-homeinfo';

    configuratorBaseUrl = '';

    homeInfoList: HomeInfo[] = [];

    homeInfo: HomeInfo = {};

    updateHomeInfoUrl = '/admin/update-homeinfo';

    homeInfoForm?:FormGroup;

    homeInfoToUpdate:HomeInfo = {};

    constructor(
        private _router: Router,
        private _toastMessageService: ToastmessageService,
        private _apiRequestService: ApiRequestService,
        @Inject('CONFIGURATOR_BASE_URL') private _configuratorBaseUrl: string,
        private _translateService: TranslationService,
        private _fb: FormBuilder
    ) {
        this.configuratorBaseUrl = _configuratorBaseUrl;
        this.createHomeInfoForm();
    }

    ngOnInit(): void {
        this._apiRequestService
            .sendRequest<{}, HomeInfoResponse>(this.configuratorBaseUrl, true, {}, true, this.getHomeInfoUrl)
            .subscribe((response) => {
                //TODO it now contains only one homeInfo!!! Will be changed
                this.homeInfoList = response.homeInfoList as HomeInfo[];
                this.homeInfoList.forEach((homeInfo) => {
                    this.homeInfo = homeInfo;
                });
            });
    }

    updateHomeInfo() {
        this.collectHomeInfoData();

        const updateHomeInfoRequest:UpdateHomeInfoRequest = {
            homeInfo:this.homeInfoToUpdate
        }

        this._apiRequestService
        .sendRequest<UpdateHomeInfoRequest, UpdateHomeInfoResponse>(this.configuratorBaseUrl, true, updateHomeInfoRequest, true, this.updateHomeInfoUrl)
        .pipe(
            catchError((error: HttpErrorResponse) => {
                return throwError(() => {
                    const httpError: HttpError = error.error as HttpError;

                        this._toastMessageService.displayToastMessage(TOAST_STATE.error, this._translateService.translate('admin.unsuccessfulSave! '+httpError.errorMessage), MAT_ICON.error);
                        this.dismissError();

                    this._router.navigateByUrl('/');
                });
            })
        )
        .subscribe((response) => {
            this._toastMessageService.displayToastMessage(
                TOAST_STATE.success,
                response.responseMessage as string,
                MAT_ICON.success
            );
            this.dismissError();
        });


    }

    onChangeHomeInfo() {
        console.log(this.homeInfo.value)
    }

    createHomeInfoForm() {
        this.homeInfoForm = this._fb.group({
            value: ['', [Validators.required]],

        });
    }

    collectHomeInfoData() {
        this.homeInfoToUpdate = {
            value: this.homeInfoForm?.controls['value'].value as string | undefined,
            id:this.homeInfo.id

        };
    }

    private dismissError(): void {
        setTimeout(() => {
            this._toastMessageService.dismissToast();
        }, 3000);
    }

}
