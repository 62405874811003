<form [formGroup]="htmlForm!" novalidate>
    <div class="ngx-wrapper">
        <ngx-editor-menu [toolbar]="toolbar" [editor]="editor!"> </ngx-editor-menu>
        <ngx-editor
            [editor]="editor!"
            [ngModel]="html"
            [disabled]="false"
            [placeholder]="'Írjon ide...'"
            [editable]="true"
            formControlName="html"
        ></ngx-editor>
    </div>
</form>
