<div class="contact-content">
    <div class="back-button">
        <mat-icon routerLink="/series">arrow_back</mat-icon>
    </div>
    <h3>SOROZAT MÓDOSÍTÁSA</h3>
    <div class="contact-form">
        <form [formGroup]="serieForm!" novalidate>
            <div class="field-container">
                <div class="field">
                    <span>Sorozat neve</span>
                    <div class="data">
                        <input
                            [(ngModel)]="serie.serieName"
                            formControlName="serieName"
                            id="serieName"
                            class="form-control"
                            type="text"
                            required
                        />
                    </div>
                </div>
            </div>
        </form>
    </div>
</div>
<!-- <app-addproductstoserie
    *ngIf="_router.url === '/addserie'"
    [serieForm]="serieForm"
    [selectedBookList]="selectedBookList"
    [filteredProduct]="filteredProduct"
></app-addproductstoserie>
<app-addproductstoserie
    *ngIf="_router.url === '/series/' + serie.routerLink"
    [serieForm]="serieForm"
    [existingProducts]="selectedBookList"
    [filteredProduct]="filteredProduct"
></app-addproductstoserie> -->
<div class="contact-content-books">
    <h3>SOROZAT ELEMEINEK MÓDOSÍTÁSA</h3>
    <div class="drag-and-drop-container">
        <div class="search-main-container">
            <div class="search-bar">
                <div class="search-tool">
                    <mat-icon aria-hidden="false" aria-label="keyboard_arrow_right icon" fontIcon="search"></mat-icon>
                    <input [(ngModel)]="listFilter" type="search" placeholder="Szerző, cím neve" />
                </div>
            </div>
            <div class="title">
                <h3>{{ serie.serieName! }} csomag tartalma</h3>
            </div>
        </div>
        <div class="drag-and-drop">
            <div class="products-container">
                <div class="products">
                    <div *ngFor="let product of filteredBooks" class="product">
                        {{ product.author + ': ' + product.title }}
                        <mat-icon (click)="addProduct(product)">add_circle_outline</mat-icon>
                    </div>
                </div>
            </div>
            <div class="title-mobile">
                <h3>{{ serie.serieName }} csomag tartalma</h3>
            </div>
            <div class="products-container">
                <div class="products">
                    <div *ngFor="let product of selectedBookList; let i = index" class="product">
                        {{ product.book?.author + ': ' + product.book?.title }} {{ i + 1 }}. rész
                        <mat-icon (click)="decreaseSerieNumber(product)" *ngIf="i !== 0">keyboard_arrow_up</mat-icon>
                        <mat-icon (click)="increaseSerieNumber(product)" *ngIf="i !== selectedBookList.length - 1 || i === 0"
                            >keyboard_arrow_down</mat-icon
                        >
                        <mat-icon (click)="deleteFromSerie(product)">remove_circle_outline</mat-icon>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>

<div class="save-button">
    <div [ngStyle]="{ 'margin-top': '8px' }" class="field-container">
        <div [ngStyle]="{ 'justify-content': 'center', 'margin-top': '8px', 'margin-bottom': '8px' }" class="field">
            <div [ngStyle]="{ 'justify-content': 'center' }" class="data">
                <button
                    (click)="updateSerie()"
                    [ngStyle]="{ background: '#e8fcfd', border: '1px solid #0E8388', 'font-weight': 'bold', width: '100%' }"
                >
                    SOROZAT MÓDOSÍTÁSA
                </button>
            </div>
        </div>
    </div>
</div>
