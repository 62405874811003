import { Injectable } from "@angular/core";
import { GetTranslationRequest } from "./read-translation-request";
import { GetTranslationResponse } from "./read-translation-response";
import { Observable } from 'rxjs';

import { map } from 'rxjs/operators';

import { HttpClient } from '@angular/common/http';
import { UseCase } from '@prekog/pw-common';
import { LogService } from '@prekog/pw-common';
import { BusinessLogicError } from '@prekog/pw-common';


@Injectable ({
    providedIn: 'root'
})

export class GetTranslationUseCase extends UseCase<
GetTranslationRequest,
Observable<GetTranslationResponse>
> {

    constructor(protected override _logService: LogService, protected override _http: HttpClient) {
        super(_logService,_http);
    }

    //TODO: logic can be moved to common, no need separate usecase for this
    protected override executeBusinessLogic(request:GetTranslationRequest): Observable<GetTranslationResponse>  {
        return this._http.get(request.url).pipe(
            map((content) => {
                if (!content) {
                    throw new BusinessLogicError("Couldn't read translate file content! "+request.url);
                }
                const response = { content: content };
                return response;
            })
        );
    }

}
