import { Component, Inject, OnInit } from '@angular/core';
import { BannerConfig } from './orderbanners/bannerconfig';
import { ApiRequestService, ToastmessageService } from '@prekog/pw-common';
import { GetBannersResponse } from './orderbanners/getbannersresponse';
import { BreakpointObserver, BreakpointState, Breakpoints } from '@angular/cdk/layout';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { DeletebannerpopupComponent } from './deletebannerpopup/deletebannerpopup.component';
import { Observable } from 'rxjs';
import { Router } from '@angular/router';

@Component({
    selector: 'app-addbanners',
    templateUrl: './banners.component.html',
    styleUrls: ['./banners.component.scss']
})
export class BannersComponent implements OnInit {
    getBannersUrl = '/admin/get-banners';

    banners: BannerConfig[] = [];

    filteredBanner: BannerConfig[] = [];

    configuratorBaseUrl = '';

    private _listFilter = '';

    get listFilter(): string {
        return this._listFilter;
    }
    set listFilter(value: string) {
        this._listFilter = value;
        this.filteredBanner = this.performFilter(value);
    }

    isExtraSmall: Observable<BreakpointState>;

    dialogConfiger: MatDialogConfig = {
        width: '60%',
        backdropClass: 'back-drop-background',
        hasBackdrop: true
    };
    enterAnimationDuration = '500ms';
    exitAnimationDuration = '200ms';

    constructor(
        private _toastMessageService: ToastmessageService,
        private _apiRequestService: ApiRequestService,
        @Inject('CONFIGURATOR_BASE_URL') private _configuratorBaseUrl: string,
        private _matDialog: MatDialog,
        private readonly breakpointObserver: BreakpointObserver,
        private _router: Router
    ) {
        this.configuratorBaseUrl = _configuratorBaseUrl;
        this.isExtraSmall = this.breakpointObserver.observe(Breakpoints.XSmall);
    }

    ngOnInit(): void {
        if (sessionStorage.getItem('token')) {
            this._apiRequestService
                .sendRequest<{}, GetBannersResponse>(this.configuratorBaseUrl, true, {}, true, this.getBannersUrl)
                .subscribe((response) => {
                    this.banners = response.bannerConfigEntityList as BannerConfig[];
                    this.filteredBanner = this.banners;
                });
        } else {
            this._router.navigateByUrl('/');
        }
    }

    performFilter(filterBy: string): BannerConfig[] {
        filterBy = filterBy.toLocaleLowerCase();
        return this.banners.filter((banner: BannerConfig) => banner.title!.toLocaleLowerCase().includes(filterBy));
    }

    private dismissError(): void {
        setTimeout(() => {
            this._toastMessageService.dismissToast();
        }, 3000);
    }

    openDeleteBannerPopup(banner: BannerConfig) {
        const popupDialog = this._matDialog.open(DeletebannerpopupComponent, {
            data: banner,
            width: 'calc(100% - 50px)',
            maxWidth: '100vw',
            height: '80%'
        });
        const xsmallDialogSubscription = this.isExtraSmall.subscribe((size) => {
            if (size.matches) {
                popupDialog.updateSize('100vw', '100%');
            } else {
                popupDialog.updateSize('calc(60% - 50px)', '');
            }
        });

        popupDialog.afterClosed().subscribe(() => {
            xsmallDialogSubscription.unsubscribe();
        });
    }
}
