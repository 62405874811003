import { CdkDragDrop, moveItemInArray } from '@angular/cdk/drag-drop';
import { Component, Inject, OnInit } from '@angular/core';
import { BannerConfig } from './bannerconfig';
import { ApiRequestService, HttpError, MAT_ICON, TOAST_STATE, ToastmessageService } from '@prekog/pw-common';
import { GetBannersResponse } from './getbannersresponse';
import { UpdateBannerOrderRequest } from './updatebannerorderrequest';
import { UpdateBannerOrderResponse } from './updatebannerconfigresponse';
import { catchError, throwError } from 'rxjs';
import { HttpErrorResponse } from '@angular/common/http';
import { BannerConfigOrder } from './bannerconfigorder';
import { Router } from '@angular/router';

@Component({
    selector: 'app-banners',
    templateUrl: './orderbanners.component.html',
    styleUrls: ['./orderbanners.component.scss']
})
export class OrderBannersComponent implements OnInit {
    banners: BannerConfig[] = [];

    configuratorBaseUrl = '';

    getBannersUrl = '/admin/get-banners';

    updateBannerUrl = '/admin/update-banner-order';

    constructor(
        private _toastMessageService: ToastmessageService,
        private _apiRequestService: ApiRequestService,
        @Inject('CONFIGURATOR_BASE_URL') private _configuratorBaseUrl: string,
        private _router: Router
    ) {
        this.configuratorBaseUrl = _configuratorBaseUrl;
    }

    ngOnInit(): void {
        if (sessionStorage.getItem('token')) {
            this._apiRequestService
                .sendRequest<{}, GetBannersResponse>(this.configuratorBaseUrl, true, {}, true, this.getBannersUrl)
                .subscribe((response) => {
                    this.banners = response.bannerConfigEntityList as BannerConfig[];
                });
        } else {
            this._router.navigateByUrl('/');
        }
    }

    drop(event: CdkDragDrop<string[]>) {
        moveItemInArray(this.banners, event.previousIndex, event.currentIndex);
    }

    //TODO make apiCall!!!
    updateBannerOrder() {
        let bannerConfigOrderList: BannerConfigOrder[] = [];

        this.banners.forEach((banner, index) => {
            const bannerConfigOrder = {
                id: banner.id,
                orderNumber: index
            };
            bannerConfigOrderList.push(bannerConfigOrder);
        });
        const updateBannerOrderRequest: UpdateBannerOrderRequest = {
            bannerConfigList: bannerConfigOrderList
        };

        this._apiRequestService
            .sendRequest<UpdateBannerOrderRequest, UpdateBannerOrderResponse>(
                this.configuratorBaseUrl,
                true,
                updateBannerOrderRequest,
                true,
                this.updateBannerUrl
            )
            .pipe(
                catchError((error: HttpErrorResponse) => {
                    return throwError(() => {
                        const httpError: HttpError = error.error as HttpError;

                        this._toastMessageService.displayToastMessage(
                            TOAST_STATE.error,
                            'Sikertelen módosítás' + httpError.errorMessage,
                            MAT_ICON.error
                        );
                        this.dismissError();
                    });
                })
            )
            .subscribe((response) => {
                this._toastMessageService.displayToastMessage(TOAST_STATE.success, response.responseMessage!, MAT_ICON.success);
                this.dismissError();
            });
    }

    private dismissError(): void {
        setTimeout(() => {
            this._toastMessageService.dismissToast();
        }, 3000);
    }
}
