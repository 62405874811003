<div class="app">
    <!-- <div class="header-container">
        <div class="header-admin">
            <h2>Multiverzum Admin felület</h2> -->
    <!-- <a routerLink="/products"><h3>Termék feltöltése</h3></a> -->
    <!-- </div>
    </div> -->
    <lib-toastmessage></lib-toastmessage>
    <!-- <pw-headermodule
        *ngIf="_router.url !== '/'"
        [colorOfNavBar]="'black'"
        [nameOfTheSite]="title"
        [hasSearchBar]="false"
        [hasLogo]="false"
        [hasShoppingCart]="false"
        [hasLogin]="true"
        [headerTitle]="headerTitle"
        [hasAdminLogo]="true"
        [logoUrl]="logoUrl"
        [hasAdmin]="true"
    ></pw-headermodule> -->
    <pw-navigationmodule
        *ngIf="_router.url !== '/'"
        [nameOfTheSite]="title"
        [logoPosition]="positionL"
        [hasLogo]="false"
        [isShoppingCart]="false"
        [hasLogin]="hasLogin"
        [colorOfNavBar]="colorOfNavBar"
        [isAdmin]="true"
        [adminHomeUrl]="adminHomeUrl"
    ></pw-navigationmodule>
    <router-outlet (activate)="loadRouterOutletInput($event)"></router-outlet>
    <div class="footer-container">
        <div class="footer-admin">
            <h1></h1>
        </div>
    </div>
</div>
