<div class="popup-main">
    <div class="popup">
        <div class="mat-dialog-actions" mat-dialog-actions [align]="selectedAlign">
            <button mat-button mat-dialog-close><mat-icon>close</mat-icon></button>
        </div>
        <h3>Kérem adja meg az akciós árat!</h3>
        <div class="text">
            <div class="input">
                <form [formGroup]="discountPriceForm!" novalidate>
                    <input
                        formControlName="discountPrice"
                        id="discountPrice"
                        class="form-control"
                        type="number"
                        required
                        placeholder="Akciós ár"
                    />
                </form>
            </div>
        </div>
        <div class="submit">
            <button (click)="addDiscountPrice()">Akciós ár mentése</button>
        </div>
    </div>
</div>
