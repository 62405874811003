import { Component, Inject, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { ApiRequestService, MAT_ICON, TOAST_STATE, ToastmessageService,HttpError, TranslationService } from '@prekog/pw-common';
import { Package } from '../package';
import { GetPackageRequest } from '../updatepackage/getpackagerequest';
import { CdkDragDrop, moveItemInArray, transferArrayItem } from '@angular/cdk/drag-drop';
import { CreatePackageProductRequest } from './createpackageproductrequest';
import { PackageProduct } from './packageproduct';
import { CreatePackageProductResponse } from './createpackageproductresponse';
import { AddPackageRequest } from '../addpackage/addpackagerequest';
import { AddPackageResponse } from '../addpackage/addpackageresponse';
import { map } from 'rxjs';
import { GetProductsResponse } from '../../products/getproductsresponse';
import { HttpErrorResponse } from '@angular/common/http';
import { catchError, throwError } from 'rxjs';
import { Material } from '../../products/material';

@Component({
    selector: 'app-addproductstopackage',
    templateUrl: './addproductstopackage.component.html',
    styleUrls: ['./addproductstopackage.component.scss']
})
export class AddproductstopackageComponent implements OnInit {
    storeBaseUrl = '';

    package: Package = {};

    getPackageUrl = '/admin/getpackage';

    createPackageProductUrl = '/admin/createpackageproduct';

    getPackageRequest: GetPackageRequest = {};

    products: Material[] = [];

    productsToPackage: Material[] = [];

    storeExistingProductsURI = '/store/get-products';

    createPackageProductRequest: CreatePackageProductRequest = {};

    addPackageRequest: AddPackageRequest = {};

    addPackageResponse: AddPackageResponse = {};

    addPackageUrl = '/admin/addpackage';



    constructor(
        private _router: Router,
        private _route: ActivatedRoute,
        private _toastMessageService: ToastmessageService,
        @Inject('FILE_MANAGER_URL') private _fileManagerUrl: string,
        @Inject('STORE_BACKEND_URL') private _storeBaseUrl: string,
        private _apiRequestService: ApiRequestService,
        private _translateService: TranslationService,
    ) {
        this.storeBaseUrl = _storeBaseUrl;
    }
    ngOnInit(): void {
        if (!sessionStorage.getItem('token')) {
            this._router.navigateByUrl('/');
        } else if (sessionStorage.getItem('token')) {

            this._route.paramMap.pipe(map(() => window.history.state)).subscribe((res:Package) => {
                this.package = {
                    packageName:res.packageName,
                    imageUrl:res.imageUrl
                }
            });

            this._apiRequestService.sendRequest(this.storeBaseUrl, true, {}, true, this.storeExistingProductsURI).subscribe((response) => {
                const products = response as GetProductsResponse;
                this.products = products.productList as Material[];

            });
        }


    }

    drop(event: CdkDragDrop<Material[]>) {
        if (event.previousContainer === event.container) {
            moveItemInArray(event.container.data, event.previousIndex, event.currentIndex);
        } else {
            transferArrayItem(event.previousContainer.data, event.container.data, event.previousIndex, event.currentIndex);
        }
    }

    //TODO check ig product has packagePrice!
    createPackageProduct(productsToPackage: Material[]) {


        this.addPackageRequest = {
            packageObject: this.package
        };

        this._apiRequestService
            .sendRequest<AddPackageRequest, AddPackageResponse>(this.storeBaseUrl, true, this.addPackageRequest, true, this.addPackageUrl)
            .pipe(
                catchError((error: HttpErrorResponse) => {
                    return throwError(() => {
                        const httpError: HttpError = error.error as HttpError;

                            this._toastMessageService.displayToastMessage(TOAST_STATE.error, this._translateService.translate('admin.unsuccessfulSave! '+httpError.errorMessage), MAT_ICON.error);
                            this.dismissError();

                        this._router.navigateByUrl('/');
                    });
                })
            )
            .subscribe((response) => {
                //TODO separate success message from error message!!!
                this._toastMessageService.displayToastMessage(TOAST_STATE.success, response.message!, MAT_ICON.success);
                this.dismissError();
                this.package.routerLink = response.routerLink;

                let packageProductList: PackageProduct[] = [];
                productsToPackage.forEach((productsToPackage) => {
                    const packageProduct: PackageProduct = new PackageProduct();
                    packageProduct.productId = productsToPackage.id;
                    packageProduct.packageObject = this.package;
                    packageProductList.push(packageProduct);
                });


                this.createPackageProductRequest = {
                    packageProduct: packageProductList
                };

                this._apiRequestService
                    .sendRequest<CreatePackageProductRequest, CreatePackageProductResponse>(
                        this.storeBaseUrl,
                        true,
                        this.createPackageProductRequest,
                        true,
                        this.createPackageProductUrl
                    )
                    .pipe(
                        catchError((error: HttpErrorResponse) => {
                            return throwError(() => {
                                const httpError: HttpError = error.error as HttpError;

                                    this._toastMessageService.displayToastMessage(TOAST_STATE.error, this._translateService.translate('admin.unsuccessfulSave! '+httpError.errorMessage), MAT_ICON.error);
                                    this.dismissError();


                                this._router.navigateByUrl('/');
                            });
                        })
                    )
                    .subscribe((responseMessage) => {
                        this._toastMessageService.displayToastMessage(
                            TOAST_STATE.success,
                            responseMessage.responseMessage!,
                            MAT_ICON.success
                        );
                        this.dismissError();
                        this._router.navigateByUrl('/existingpackages');
                    });
            });
    }

    private dismissError(): void {
        setTimeout(() => {
            this._toastMessageService.dismissToast();
        }, 3000);
    }
}
