import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialog } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { ApiRequestService, HttpError, LoadingService, MAT_ICON, TOAST_STATE, ToastmessageService, TranslationService } from '@prekog/pw-common';
import { Material } from '../../material';
import { SetPreorderPublishedRequest } from '../setpreorderpublishedrequest';
import { SetPreorderPublishedResponse } from '../setpreorderpublishedresponse';
import { HttpErrorResponse } from '@angular/common/http';
import { catchError, throwError } from 'rxjs';

@Component({
    selector: 'app-confirmsetpreorderpublishedpopup',
    templateUrl: './confirmsetpreorderpublishedpopup.component.html',
    styleUrls: ['./confirmsetpreorderpublishedpopup.component.scss']
})
export class ConfirmsetpreorderpublishedpopupComponent {
    selectedAlign: 'start' | 'center' | 'end';

    storeBaseUrl = '';

    setPublishedUrl = '/admin/setpublished';

    setPreorderPublishedRequest: SetPreorderPublishedRequest = {};

    constructor(
        @Inject(MAT_DIALOG_DATA) public product: Material,
        private _dialog: MatDialog,
        @Inject('STORE_BACKEND_URL') private _storeBaseUrl: string,
        private _apiRequestService: ApiRequestService,
        private _router: Router,
        private _toastMessageService: ToastmessageService,
        private _loadingService: LoadingService,
        private _translateService: TranslationService
    ) {
        this.selectedAlign = 'end';
        this.storeBaseUrl = _storeBaseUrl;
    }

    closeDialog() {
        this._dialog.closeAll();
    }

    setPreorderPublished() {
        this.setPreorderPublishedRequest = {
            isbn: this.product.isbn
        };
        this._loadingService.show();
        this._apiRequestService
            .sendRequest<SetPreorderPublishedRequest,SetPreorderPublishedResponse>(this.storeBaseUrl, true, this.setPreorderPublishedRequest, true, this.setPublishedUrl)
            .pipe(
                catchError((error: HttpErrorResponse) => {
                    return throwError(() => {
                        const httpError: HttpError = error.error as HttpError;

                        this._toastMessageService.displayToastMessage(
                            TOAST_STATE.error,
                            this._translateService.translate('admin.unsuccessfulSave! ' + httpError.errorMessage),
                            MAT_ICON.error
                        );
                        this.dismissError();

                    });
                })
            )
            .subscribe((response:SetPreorderPublishedResponse) => {
                //TODO somehow response is null, on backend console is all right! Check it!
                this._loadingService.hide();
                window.location.reload();
                this._dialog.closeAll();
                this._toastMessageService.displayToastMessage(
                    TOAST_STATE.success,
                    "Sikeres megjelenés és státusz állítás",
                    MAT_ICON.success
                );
                this.dismissError();
            });
    }

    private dismissError(): void {
        setTimeout(() => {
            this._toastMessageService.dismissToast();
        }, 3000);
    }
}
