import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialog } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { ApiRequestService, ToastmessageService, HttpError, TOAST_STATE, MAT_ICON, TranslationService } from '@prekog/pw-common';
import { DeleteSerieProductRequest } from '../deleteserieproductrequest';
import { DeleteSerieProductResponse } from '../deleteserieproductresponse';
import { catchError, throwError } from 'rxjs';
import { HttpErrorResponse } from '@angular/common/http';
import { deleteProductMatData } from '../deleteproductmatdata';

@Component({
    selector: 'app-confirmdeleteproductfromseriepopupcomponent',
    templateUrl: './confirmdeleteproductfromseriepopupcomponent.component.html',
    styleUrls: ['./confirmdeleteproductfromseriepopupcomponent.component.scss']
})
export class ConfirmdeleteproductfromseriepopupcomponentComponent {
    selectedAlign: 'start' | 'center' | 'end';

    storeBaseUrl = '';

    deleteSerieproductUrl = '/admin/delete-serieproduct';

    deleteSerieProductRequest: DeleteSerieProductRequest = {};

    constructor(
        @Inject(MAT_DIALOG_DATA) public deleteProductMatData: deleteProductMatData,
        private _dialog: MatDialog,
        @Inject('STORE_BACKEND_URL') private _storeBackendUrl: string,
        private _apiRequestService: ApiRequestService,
        private _router: Router,
        private _toastMessageService: ToastmessageService,
        private _translateService: TranslationService
    ) {
        this.selectedAlign = 'end';
        this.storeBaseUrl = _storeBackendUrl;
    }

    removeProductFromSerie() {
        this.deleteSerieProductRequest = {
            productId: this.deleteProductMatData.productId
        };

        this._apiRequestService
            .sendRequest<DeleteSerieProductRequest, DeleteSerieProductResponse>(
                this.storeBaseUrl,
                true,
                this.deleteSerieProductRequest,
                true,
                this.deleteSerieproductUrl
            )
            .pipe(
                catchError((error: HttpErrorResponse) => {
                    return throwError(() => {
                        const httpError: HttpError = error.error as HttpError;

                        this._toastMessageService.displayToastMessage(
                            TOAST_STATE.error,
                            this._translateService.translate('admin.unsuccessfulSave! ' + httpError.errorMessage),
                            MAT_ICON.error
                        );
                        this.dismissError();
                        this.closeDialog();
                        this._router.navigateByUrl('/');
                    });
                })
            )
            .subscribe((response) => {
                this._toastMessageService.displayToastMessage(TOAST_STATE.success, response.responseMessage!, MAT_ICON.success);
                this.dismissError();
                this.closeDialog();
            });
    }

    private dismissError(): void {
        setTimeout(() => {
            this._toastMessageService.dismissToast();
        }, 3000);
    }

    closeDialog() {
        this._dialog.closeAll();
        window.location.reload();
    }
}
