import { enableProdMode } from '@angular/core';
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';

import { AppModule } from './app/app.module';
import { environment } from './environments/environment';

if (environment.production) {
    enableProdMode();
}

export function getOrderBackendUrl(): string {
    return environment.backendOrderUrl;
}
export function getEmailBackendUrl(): string {
    return environment.backendEmailUrl;
}
export function getStoreBackendUrl(): string {
    return environment.backendStoreUrl;
}

export function getApiUrl(): string {
    return environment.apiUrl;
}
export function getBackendAuthUrl(): string {
    return environment.backendAuthUrl;
}
export function getDownloadFileUrl(): string {
    return environment.downloadUrl;
}
export function getFileManagerUrl(): string {
    return environment.backendFileManagerUrl;
}

export function getConfiguratorUrl(): string {
    return environment.backendConfiguratorUrl;
}


const providers = [
    { provide: 'ORDER_BACKEND_URL', useFactory: getOrderBackendUrl, deps: [] },
    { provide: 'EMAIL_BACKEND_URL', useFactory: getEmailBackendUrl, deps: [] },
    { provide: 'STORE_BACKEND_URL', useFactory: getStoreBackendUrl, deps: [] },
    { provide: 'AUTH_BACKEND_URL', useFactory: getBackendAuthUrl, deps: [] },
    { provide: 'CONFIGURATOR_BASE_URL', useFactory: getConfiguratorUrl, deps: [] },
    { provide: 'API_URL', useFactory: getApiUrl, deps: [] },
    { provide: 'DOWNLOAD_FILE_URL', useFactory: getDownloadFileUrl, deps: [] },
    { provide: 'FILE_MANAGER_URL', useFactory: getDownloadFileUrl, deps: [] }


];

platformBrowserDynamic()
    .bootstrapModule(AppModule)
    .catch((err) => console.error(err));
