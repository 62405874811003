<div class="contact-content">
    <div class="back-button">
        <mat-icon routerLink="/homeconfig">arrow_back</mat-icon>
    </div>
    <div>
        <h3>KEZDŐOLDAL ELEMÉNEK MÓDOSÍTÁSA</h3>
    </div>
    <div class="contact-form">
        <form [formGroup]="homeConfigForm!" novalidate>
            <div class="field-container">
                <div class="field">
                    <span>Kezdőoldal elemének neve</span>
                    <div class="data">
                        <input [(ngModel)]="homeConfig.name" formControlName="name" id="name" class="form-control" type="text" required />
                    </div>
                </div>
            </div>
        </form>
        <div class="form-not-required">
            <div class="field-container">
                <div class="field">
                    <span>Aktív?</span>
                    <div class="data">
                        <mat-checkbox
                            (change)="onChangeActive()"
                            #activeInput
                            [ngModel]="active"
                            id="active"
                            class="form-control"
                            type="checkbox"
                            required
                            [ngStyle]="{ 'font-weight': 'bold' }"
                        ></mat-checkbox>
                    </div>
                </div>
            </div>
        </div>
        <div [ngStyle]="{ 'margin-top': '8px' }" class="field-container">
            <div [ngStyle]="{ 'justify-content': 'center', 'margin-top': '8px', 'margin-bottom': '8px' }" class="field">
                <div [ngStyle]="{ 'justify-content': 'center' }" class="data">
                    <button
                        (click)="updateHomeConfig()"
                        [ngStyle]="{ background: '#e8fcfd', border: '1px solid #0E8388', 'font-weight': 'bold', width: '100%' }"
                        [disabled]="!homeConfigForm?.valid"
                    >
                        KEZDŐOLDAL ELEMÉNEK MÓDOSÍTÁSA
                    </button>
                </div>
            </div>
        </div>
    </div>
</div>
