<div class="add-home-info-container">
    <div class="home-info-title-container">
        <h3>Információs szöveg módosítása html formátumban</h3>
        <a href="https://www.w3schools.com/html/" target="_blank">Segítség a html szerkesztéséhez</a>
    </div>
    <div>
        <form [formGroup]="homeInfoForm!" novalidate>
            <textarea
                formControlName="value"
                id="value"
                class="form-control"
                type="text"
                required
                [ngModel]="homeInfo.value"
                class="input"
            ></textarea>
        </form>
    </div>

    <div [ngStyle]="{ 'margin-top': '8px' }" class="field-container">
        <div [ngStyle]="{ 'justify-content': 'center', 'margin-top': '8px', 'margin-bottom': '8px' }" class="field">
            <div [ngStyle]="{ 'justify-content': 'center' }" class="data">
                <button
                    (click)="updateHomeInfo()"
                    [ngStyle]="{ background: '#e8fcfd', border: '1px solid #0E8388', 'font-weight': 'bold', width: '100%' }"
                    [disabled]="!homeInfoForm?.valid"
                >
                    INFORMÁCIÓS SZÖVEG MÓDOSÍTÁSA
                </button>
            </div>
        </div>
    </div>
</div>
