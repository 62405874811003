<div class="packages">
    <div class="title">
        <div class="tit">
            <h3>AKCIÓK</h3>
            <div class="add-icon">
                <mat-icon routerLink="/addproductsale">add_circle</mat-icon>
            </div>
        </div>
    </div>
    <div class="add-new-package">
        <div class="search-bar">
            <div class="search-tool">
                <input [(ngModel)]="listFilter" type="search" placeholder="Akció neve" />
                <div class="icon">
                    <mat-icon aria-hidden="false" aria-label="keyboard_arrow_right icon" fontIcon="search"></mat-icon>
                </div>
            </div>
        </div>
        <!-- <button routerLink="/addpackage">ÚJ CSOMAG HOZZÁADÁSA</button> -->
    </div>
    <div *ngFor="let onSalePeriod of filteredOnSalePeriods; let i = index" class="package">
        <div class="pro">
            <div class="name">
                <div
                    [ngStyle]="i % 2 === 0 ? { background: 'white' } : { background: '#efeff5' }"
                    class="button"
                    routerLink="{{ onSalePeriod.routerLink }}"
                >
                    {{ onSalePeriod.name }}
                </div>
            </div>
        </div>
    </div>
</div>
