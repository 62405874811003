import { HttpErrorResponse } from '@angular/common/http';
import { Component, ElementRef, Inject, OnInit, ViewChild } from '@angular/core';
import { UploadToAnExistingFolderRequest } from '../uploadtoanexistingfolderrequest';
import { ApiRequestService, MAT_ICON, TOAST_STATE, ToastmessageService, HttpError, TranslationService } from '@prekog/pw-common';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { UploadFileResponse } from './uploadfileresponse';
import { Router } from '@angular/router';
import { AddProductRequest } from './addproductrequest';
import { AddProductResponse } from './addproductresponse';
import { catchError, throwError, take, forkJoin, map } from 'rxjs';
import { MatDatepicker } from '@angular/material/datepicker';
import { Material } from '../material';

@Component({
    selector: 'pw-addproduct',
    templateUrl: './addproduct.component.html',
    styleUrls: ['./addproduct.component.scss']
})
export class AddproductComponent implements OnInit {
    product: Material = {};

    productForm?: FormGroup;

    htmlForm?: FormGroup;

    txt = '';

    uploadFileUrl = '/admin/uploadFile';

    addProductUrl = '/admin/addproduct';

    @ViewChild('fileCoverInput') fileCoverInput!: ElementRef;
    fileCover: File | null = null;

    @ViewChild('fileBlurbInput') fileBlurbInput!: ElementRef;
    fileBlurb: File | null = null;

    @ViewChild('fileBookExtractInput') fileBookExtractInput!: ElementRef;
    fileBookExtract: File | null = null;

    fileManagerUrl = '';

    uploadToAnExistingFolderRequest?: UploadToAnExistingFolderRequest = {};

    uploadFileResponse: UploadFileResponse = {};

    downloadUri = '';

    addProductRequest: AddProductRequest = {};

    errorMessageCover = 'Kérem válassza ki a borítóképet a termék hozzáadása előtt!';

    errorMessageBlurb = 'Kérem válassza a fülszöveget a termék hozzáadása előtt!';

    html = '';

    url = '';

    bookExtractUrl = '';

    storeBaseUrl = '';
    @ViewChild('isAvailableInput') isAvailableInput!: ElementRef;
    isAvailable = false;
    @ViewChild('hasPreorderInput') hasPreorderInput!: ElementRef;
    hasPreorder = false;
    @ViewChild('webshopPriceActiveInput') webshopPriceActiveInput!: ElementRef;
    webshopPriceActive = false;
    @ViewChild('discountPriceActiveInput') discountPriceActiveInput!: ElementRef;
    discountPriceActive = false;

    @ViewChild(MatDatepicker) datepicker?: MatDatepicker<Date>;

    addCoverFile = false;

    addBlurbFile = false;

    addBookExtractFile = false;

    @ViewChild('discountPriceInput') discountPriceInput!: ElementRef;
    discountPrice: number | null = null;
    @ViewChild('translatorInput') translatorInput!: ElementRef;
    translator = undefined;
    @ViewChild('packagePriceInput') packagePriceInput!: ElementRef;
    packagePrice: number | null = null;
    @ViewChild('webshopPriceInput') webshopPriceInput!: ElementRef;
    webshopPrice: number | null = null;
    @ViewChild('illustratorInput') illustratorInput!: ElementRef;
    illustrator = undefined;
    @ViewChild('subtitleInput') subtitleInput!: ElementRef;
    subtitle = undefined;
    @ViewChild('preorderPriceInput') preorderPriceInput!: ElementRef;
    preorderPrice: number | null = null;

    numberPattern = '^-?[0-9]\\d*(\\.\\d{1,2})?$';

    errorWebshop = '';

    maxFileSize = 5000000;

    constructor(
        private _router: Router,
        private _fb: FormBuilder,
        @Inject('FILE_MANAGER_URL') private _fileManagerUrl: string,
        @Inject('STORE_BACKEND_URL') private _storeBaseUrl: string,
        private _apiRequestService: ApiRequestService,
        private _toastMessageService: ToastmessageService,
        private _translateService: TranslationService
    ) {
        this.fileManagerUrl = _fileManagerUrl;
        this.storeBaseUrl = _storeBaseUrl;
        this.createProductForm();
        this.createHtmlForm();
    }
    ngOnInit(): void {
        if (!sessionStorage.getItem('token')) {
            this._router.navigateByUrl('/');
        } else {
            this.product = JSON.parse(sessionStorage.getItem('product') as string) as Material;
        }
    }
    onSubmitdiscountPrice() {
        return this.discountPrice;
    }
    onChangeCover(event: Event) {
        const target = event.target as HTMLInputElement;
        this.fileCover = target.files![0];
        if (this.fileCover.name.split('.').pop() === 'png') {
            this.addCoverFile = true;
        }
        else {
            this.addCoverFile = false;
            this._toastMessageService.displayToastMessage(TOAST_STATE.warning, 'Kérem ".png" kiterjesztésű fájlt töltsön fel!', MAT_ICON.warning);
            this.dismissError();
        }

    }

    onChangeBookExtract(event: Event) {
        const target = event.target as HTMLInputElement;
        this.fileBookExtract = target.files![0];
        if (this.fileBookExtract.name.split('.').pop() === 'pdf') {
            this.addBookExtractFile = true;
        }
        else {
            this.addBookExtractFile = false;
            this._toastMessageService.displayToastMessage(TOAST_STATE.warning, 'Kérem ".pdf" kiterjesztésű fájlt töltsön fel!', MAT_ICON.warning);
            this.dismissError();
        }

    }

    onChangeDiscountPrice() {
        this.discountPrice = this.discountPriceInput.nativeElement.value;
    }

    onChangeWebshopPrice() {
        this.webshopPrice = this.webshopPriceInput.nativeElement.value;
    }

    onChangePreorderPrice() {
        this.preorderPrice = this.preorderPriceInput.nativeElement.value;
    }

    onChangeTranslator() {
        this.translator = this.translatorInput.nativeElement.value;
    }

    onChangeIllustrator() {
        this.illustrator = this.illustratorInput.nativeElement.value;
    }

    onChangeSubtitle() {
        this.subtitle = this.subtitleInput.nativeElement.value;
    }

    onChangePackagePrice() {
        this.packagePrice = this.packagePriceInput.nativeElement.value;
    }

    onChangeIsAvailable() {
        this.isAvailable = !this.isAvailable;
    }

    onChangeDiscountPriceActive() {
        this.discountPriceActive = !this.discountPriceActive;
        if (this.discountPriceActive) {
            this.webshopPriceActive = false;
            this.hasPreorder = false;
        }
    }

    onChangePreorderPriceActive() {
        this.hasPreorder = !this.hasPreorder;
        if (this.hasPreorder) {
            this.isAvailable = false;
            this.webshopPriceActive = false;
            this.discountPriceActive = false;
        }
    }

    onChangeWebshopPriceActive() {
        this.webshopPriceActive = !this.webshopPriceActive;
        if (this.webshopPriceActive) {
            this.discountPriceActive = false;
            this.hasPreorder = false;
        }
    }


    addProductWithFiles() {
        if (!this.addCoverFile) {
            this._toastMessageService.displayToastMessage(TOAST_STATE.warning, this.errorMessageCover, MAT_ICON.warning);
            this.dismissError();
            return;
        }
        // } else if (!this.addBlurbFile) {
        //     this._toastMessageService.displayToastMessage(TOAST_STATE.warning, this.errorMessageBlurb, MAT_ICON.warning);
        //     this.dismissError();
        //     return;
        // }

        if (
            this.fileCover?.size! > this.maxFileSize ||
            //this.fileBlurb?.size! > this.maxFileSize ||
            this.fileBookExtract?.size! > this.maxFileSize
        ) {
            this._toastMessageService.displayToastMessage(
                TOAST_STATE.error,
                this._translateService.translate('A maximum fájl korlát 5MB. Kérem ennél kisebb méretű fájlt töltsön fel!'),
                MAT_ICON.error
            );
            this.dismissError();
            return;
        } else {
            const fileCoverObservable = this._apiRequestService
                .uploadFile(this.fileCover!, true, true, this.fileManagerUrl, this.uploadFileUrl)
                .pipe(take(1));

            if (this.addBookExtractFile) {
                const fileBookExtractObservable = this._apiRequestService
                    .uploadFile(this.fileBookExtract!, true, true, this.fileManagerUrl, this.uploadFileUrl)
                    .pipe(take(1));
                return forkJoin([fileCoverObservable, fileBookExtractObservable])
                    .pipe(
                        map(([fileCover, fileBookExtract]) => {
                            const fileCoverResponse = fileCover as UploadFileResponse;
                            const fileBookExtractResponse = fileBookExtract as UploadFileResponse;
                            this.url = fileCoverResponse.downloadUri as string;
                            this.bookExtractUrl = fileBookExtractResponse.downloadUri as string;
                            this.addProduct();
                        })
                    )
                    .subscribe(() => { });
            } else {
                return forkJoin([fileCoverObservable])
                    .pipe(
                        map(([fileCover]) => {
                            const fileCoverResponse = fileCover as UploadFileResponse;
                            this.url = fileCoverResponse.downloadUri as string;
                            this.addProduct();
                        })
                    )
                    .subscribe(() => { });
            }
        }
    }
    addProduct() {
        this.collectProductData();
        this.addProductRequest = {
            product: this.product
        };

        this._apiRequestService
            .sendRequest<AddProductRequest, AddProductResponse>(this.storeBaseUrl, true, this.addProductRequest, true, this.addProductUrl)
            .pipe(
                catchError((error: HttpErrorResponse) => {
                    return throwError(() => {
                        const httpError: HttpError = error.error as HttpError;

                        this._toastMessageService.displayToastMessage(
                            TOAST_STATE.error,
                            this._translateService.translate('admin.unsuccessfulSave! ' + httpError.errorMessage),
                            MAT_ICON.error
                        );
                        this.dismissError();

                        this._router.navigateByUrl('/');
                    });
                })
            )
            .subscribe((response) => {
                this._toastMessageService.displayToastMessage(TOAST_STATE.success, response.message!, MAT_ICON.success);
                this.dismissError();
                this._router.navigateByUrl('/products');
            });
    }

    createProductForm() {
        this.productForm = this._fb.group({
            content: ['', [Validators.required]],
            author: ['', [Validators.required]],
            title: ['', [Validators.required]],
            originalPrice: ['', [Validators.required, Validators.pattern('^-?[0-9]\\d*(\\.\\d{1,2})?$')]],
            isbn: ['', [Validators.required]],
            available: ['', [Validators.required]],
            bond: ['', [Validators.required]],
            dimension: ['', [Validators.required]],
            quantityofsupply: ['', [Validators.required]]
        });
    }
    collectProductData() {
        let date = this.productForm?.controls['available'].value as string | undefined;
        const available = date?.replace(/\D/g, '-');
        this.product = {
            content: this.productForm?.controls['content'].value as string | undefined,
            author: this.productForm?.controls['author'].value as string | undefined,
            title: this.productForm?.controls['title'].value as string | undefined,
            originalPrice: this.productForm?.controls['originalPrice'].value as number | undefined,
            isbn: this.productForm?.controls['isbn'].value as number | undefined,
            available: available as Date | undefined,
            bond: this.productForm?.controls['bond'].value as string | undefined,
            dimension: this.productForm?.controls['dimension'].value as string | undefined,
            quantityOfSupply: this.productForm?.controls['quantityofsupply'].value as number | undefined,

            isAvailable: this.isAvailable,
            webshopPriceActive: this.webshopPriceActive,
            discountPriceActive: this.discountPriceActive,
            discountPrice: this.discountPrice as number,
            hasPreorder: this.hasPreorder,
            translator: this.translator,
            packagePrice: this.packagePrice as number,
            webshopPrice: this.webshopPrice as number,
            illustrator: this.illustrator,
            subtitle: this.subtitle,
            preorderPrice: this.preorderPrice as number,

            url: this.url,
            txt: this.htmlForm?.controls['html'].value as string | undefined
        };

        if (this.bookExtractUrl) {
            this.product.bookExtractUrl = this.bookExtractUrl;
        }
    }

    private dismissError(): void {
        setTimeout(() => {
            this._toastMessageService.dismissToast();
        }, 3000);
    }

    clearSelectedCoverFile() {
        this.fileCoverInput.nativeElement.value = null;
        this.addCoverFile = false;
    }

    clearSelectedBlurbFile() {
        this.fileBlurbInput.nativeElement.value = null;
        this.addBlurbFile = false;
    }

    clearSelectedBookExtractFile() {
        this.fileBookExtractInput.nativeElement.value = null;
        this.addBookExtractFile = false;
    }


    createHtmlForm() {
        this.htmlForm = this._fb.group({
            html: ['', [Validators.required]],

        });
    }
}
