import { Component, Inject, OnInit } from '@angular/core';
import { ApiRequestService } from '@prekog/pw-common';
import { PersonalData } from './personaldata';
import { RegisteredCustomersResponse } from './registeredcustomersresponse';
import { Router } from '@angular/router';

@Component({
    selector: 'app-registeredcustomers',
    templateUrl: './registeredcustomers.component.html',
    styleUrls: ['./registeredcustomers.component.scss']
})
export class RegisteredcustomersComponent implements OnInit {
    loginBaseUrl = '';

    getRegisteredCustomersUrl = '/getregisteredcustomers';

    personalData?: PersonalData[];

    filteredPersonalData?: PersonalData[];

    registeredCustomersResponse?: RegisteredCustomersResponse;

    private _listFilter = '';

    get listFilter(): string {
        return this._listFilter;
    }
    set listFilter(value: string) {
        this._listFilter = value;
        this.filteredPersonalData = this.performFilter(value);
    }

    constructor(
        private _router: Router,
        @Inject('AUTH_BACKEND_URL') private _loginUrl: string,
        private _apiRequestService: ApiRequestService
    ) {
        this.loginBaseUrl = _loginUrl;
    }

    ngOnInit(): void {
        if (sessionStorage.getItem('token')) {
            this._apiRequestService.sendRequest(this.loginBaseUrl, true, {}, true, this.getRegisteredCustomersUrl).subscribe((response) => {
                this.registeredCustomersResponse = response as RegisteredCustomersResponse;
                this.personalData = this.registeredCustomersResponse.clientList;
                this.filteredPersonalData = this.personalData;
            });
        } else {
            this._router.navigateByUrl('/');
        }
    }

    performFilter(filterBy: string): PersonalData[] {
        filterBy = filterBy.toLocaleLowerCase();
        return this.filteredPersonalData!.filter(
            (personalData: PersonalData) =>
                personalData.email?.toLocaleLowerCase().includes(filterBy) ||
                personalData.lastName?.toLocaleLowerCase().includes(filterBy) ||
                personalData.firstName?.toLocaleLowerCase().includes(filterBy) ||
                personalData.phoneNumber?.toLocaleLowerCase().includes(filterBy) ||
                personalData.companyName?.toLocaleLowerCase().includes(filterBy)
        );
    }

    sendNewsLetters() {
        //TODO backend call to email service
    }
}
