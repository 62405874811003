<div class="popup-main">
    <div class="popup">
        <div class="mat-dialog-actions" mat-dialog-actions [align]="selectedAlign">
            <button mat-button mat-dialog-close><mat-icon>close</mat-icon></button>
        </div>
        <h3>Kérem adja meg a csomagárat!</h3>
        <div class="text">
            <div class="input">
                <form [formGroup]="packagePriceForm!" novalidate>
                    <input
                        formControlName="packagePrice"
                        id="packagePrice"
                        class="form-control"
                        type="number"
                        required
                        placeholder="Csomagár"
                    />
                </form>
            </div>
        </div>
        <div class="submit">
            <button (click)="addPackagePrice()">Csomagár mentése</button>
        </div>
    </div>
</div>
