import { CustomerOrderModel } from './customer-order-modell';
import { OrderProduct } from './order-product.model';

export class OrderDetails {
    id?:number;

    customerAddress?: string;

    customerEmail?: string;

    companyName?:string;

    firstName?: string;

    lastName?: string;

    customerPhone?: string;

    customerSettlement?: string;

    customerZipCode?: number;

    deliveryAddress?: string;

    deliveryOption?: string;

    deliverySettlement?: string;

    deliveryZipCode?: string;

    orderProductList?: OrderProduct[];

    orderDetailReference?: string;

    paymentOption?: string;

    status?: string;

    previousStatus?: string;

    taxNumber?: string;

    totalPrice?: number;

    insertedTimestamp?:Date;

    insertedTimestampp?:string;

    clientId?:string;

    preOrder?:boolean;

    customerOrderReference?:string;

    orderEntity?:CustomerOrderModel;

    separated?:boolean;

    customerOrderId?:number;

    order?:CustomerOrderModel;

    failedPaymentMessageKey?:string;




}
