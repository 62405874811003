import { Component, Inject } from '@angular/core';
import { HomeConfig } from './home-config';
import { HomeConfigResponse } from './homeconfigresponse';
import { ApiRequestService, ToastmessageService, TranslationService } from '@prekog/pw-common';
import { Router } from '@angular/router';
import { CdkDragDrop, moveItemInArray } from '@angular/cdk/drag-drop';

@Component({
    selector: 'app-home-config',
    templateUrl: './home-config.component.html',
    styleUrls: ['./home-config.component.scss']
})
export class HomeConfigComponent {
    getHomeConfigsUrl = '/websiteconfigurator/get-homeconfigs';

    homeConfigList: HomeConfig[] = [];

    configuratorBaseUrl = '';

    updateHomeConfigUrl = '/admin/update-homeconfig';

    constructor(
        private _router: Router,
        private _toastMessageService: ToastmessageService,
        private _apiRequestService: ApiRequestService,
        @Inject('CONFIGURATOR_BASE_URL') private _configuratorBaseUrl: string,
        private _translateService: TranslationService
    ) {
        this.configuratorBaseUrl = _configuratorBaseUrl;
    }

    ngOnInit(): void {
        if (sessionStorage.getItem('token')) {
            this._apiRequestService
                .sendRequest<{}, HomeConfigResponse>(this.configuratorBaseUrl, true, {}, true, this.getHomeConfigsUrl)
                .subscribe((response) => {
                    this.homeConfigList = response.homeConfigList as HomeConfig[];
                });
        } else {
            this._router.navigateByUrl('/');
        }
    }

    drop(event: CdkDragDrop<string[]>) {
        moveItemInArray(this.homeConfigList, event.previousIndex, event.currentIndex);
    }

    updateHomeConfig() {}

    onChangeHomeConfigActive() {}
}
