import { Component, Inject, Input, OnInit } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { ApiRequestService, MAT_ICON, TOAST_STATE, ToastmessageService, HttpError, TranslationService } from '@prekog/pw-common';
import { Serie } from '../serie';
import { AddSerieRequest } from './addserierequest';
import { AddSerieResponse } from './addserieresponse';
import { catchError, throwError } from 'rxjs';
import { HttpErrorResponse } from '@angular/common/http';
import { GetProductsResponse } from '../../products/getproductsresponse';
import { CdkDragDrop, moveItemInArray, transferArrayItem } from '@angular/cdk/drag-drop';
import { AddProductToSerieRequest } from '../addproductstoserie/addproducttoserierequest';
import { AddProductToSerieResponse } from '../addproductstoserie/addproducttoserieresponse';
import { BookWithSerienumber } from './bookwithserienumber';
import { Observable } from 'rxjs';
import { map, startWith } from 'rxjs/operators';
import { Material } from '../../products/material';

@Component({
    selector: 'app-addserie',
    templateUrl: './addserie.component.html',
    styleUrls: ['./addserie.component.scss']
})
export class AddserieComponent implements OnInit {
    serieForm?: FormGroup;

    serieNumberForm?: FormGroup;

    serie: Serie = {};

    storeBaseUrl = '';

    addSerieRequest: AddSerieRequest = {};

    addSerieUrl = '/admin/addserie';

    products: Material[] = [];

    filteredBooks: Material[] = [];

    productsToSerie: Material[] = [];

    storeExistingProductsURI = '/store/get-products';

    displayAddProductToSerie = false;

    selectedBook: Material = {};

    filteredProduct: Material[] = [];

    selectedBookList: BookWithSerienumber[] = [];

    size = 1;

    serieNumber: number = 0;

    routerLink = '';

    addProductToSerieRequest: AddProductToSerieRequest = {};

    addProductToSerieResponse: AddProductToSerieResponse = {};

    addProductsToSerieUrl = '/admin/addproductstoserie';

    serieNumberToAdd = 0;

    private _listFilter = '';

    get listFilter(): string {
        return this._listFilter;
    }
    set listFilter(value: string) {
        this._listFilter = value;
        this.filteredProduct = this.performFilter(value);
    }

    constructor(
        private _router: Router,
        private _fb: FormBuilder,
        @Inject('STORE_BACKEND_URL') private _storeBaseUrl: string,
        private _apiRequestService: ApiRequestService,
        private _toastMessageService: ToastmessageService,
        private _translateService: TranslationService
    ) {
        this.storeBaseUrl = _storeBaseUrl;
        this.createSerieForm();
        this.createSerieNumberForm();
    }
    ngOnInit(): void {
        if (sessionStorage.getItem('token')) {
            this._apiRequestService.sendRequest(this.storeBaseUrl, true, {}, true, this.storeExistingProductsURI).subscribe((response) => {
                const products = response as GetProductsResponse;
                this.products = products.productList as Material[];
                this.filteredBooks = this.products;
            });
        } else {
            this._router.navigateByUrl('/');
        }
    }
    performFilter(filterBy: string): Material[] {
        filterBy = filterBy.toLocaleLowerCase();
        return this.products.filter(
            (publication: Material) =>
                publication.title?.toLocaleLowerCase().includes(filterBy) ||
                publication.author?.toLocaleLowerCase().includes(filterBy) ||
                publication.title?.toLocaleLowerCase().includes(filterBy)
        );
    }
    createSerieForm() {
        this.serieForm = this._fb.group({
            serieName: ['', [Validators.required]]
        });
    }

    createSerieNumberForm() {
        this.serieNumberForm = this._fb.group({
            serieNumber: ['', [Validators.required]]
        });
    }
    collectSerieData() {
        this.serie = {
            serieName: this.serieForm?.controls['serieName'].value as string | undefined
        };
    }

    collectSerieNumber() {
        this.serieNumber = this.serieNumberForm?.controls['serieNumber'].value as number;
    }

    addSerie() {
        if (this.selectedBookList.length === 0) {
            this._toastMessageService.displayToastMessage(
                TOAST_STATE.error,
                this._translateService.translate('Kérem adjon hozzá könyvet/könyveket a sorozathoz! '),
                MAT_ICON.error
            );
            this.dismissError();
        } else if (!this.serieForm?.controls['serieName'].value) {
            this._toastMessageService.displayToastMessage(
                TOAST_STATE.error,
                this._translateService.translate('Kérem adja meg a sorozat nevét! '),
                MAT_ICON.error
            );
            this.dismissError();
        } else {
            this.collectSerieData();
            this.addSerieRequest = {
                serie: this.serie
            };
            this._apiRequestService
                .sendRequest<AddSerieRequest, AddSerieResponse>(this.storeBaseUrl, true, this.addSerieRequest, true, this.addSerieUrl)
                .pipe(
                    catchError((error: HttpErrorResponse) => {
                        return throwError(() => {
                            const httpError: HttpError = error.error as HttpError;

                            this._toastMessageService.displayToastMessage(
                                TOAST_STATE.error,
                                this._translateService.translate('admin.unsuccessfulSave! ' + httpError.errorMessage),
                                MAT_ICON.error
                            );
                            this.dismissError();

                            this._router.navigateByUrl('/');
                        });
                    })
                )
                .subscribe((response) => {
                    this._toastMessageService.displayToastMessage(TOAST_STATE.success, response.responseMessage!, MAT_ICON.success);
                    this.dismissError();
                    this.serie.routerLink = response.routerLink;
                    this.routerLink = this.serie.routerLink as string;
                    this.createProductsForSerie(response.routerLink!);
                });
        }
    }

    private dismissError(): void {
        setTimeout(() => {
            this._toastMessageService.dismissToast();
        }, 3000);
    }

    drop(event: CdkDragDrop<Material[]>) {
        if (event.previousContainer === event.container) {
            moveItemInArray(event.container.data, event.previousIndex, event.currentIndex);
        } else {
            transferArrayItem(event.previousContainer.data, event.container.data, event.previousIndex, event.currentIndex);
        }
    }

    createProductsForSerie(routerLink: string) {
        this.collectSerieNumber();
        this.selectedBookList.forEach((book) => {
            this.addProductToSerieRequest = {
                routerLink: routerLink,
                serieNumber: book.serieNumber,
                productId: book.book?.id
            };

            this._apiRequestService
                .sendRequest<AddProductToSerieRequest, AddProductToSerieResponse>(
                    this.storeBaseUrl,
                    true,
                    this.addProductToSerieRequest,
                    true,
                    this.addProductsToSerieUrl
                )
                .pipe(
                    catchError((error: HttpErrorResponse) => {
                        return throwError(() => {
                            const httpError: HttpError = error.error as HttpError;

                            this._toastMessageService.displayToastMessage(
                                TOAST_STATE.error,
                                this._translateService.translate('admin.unsuccessfulSave! ' + httpError.errorMessage),
                                MAT_ICON.error
                            );
                            this.dismissError();

                            this._router.navigateByUrl('/');
                        });
                    })
                )
                .subscribe((response) => {
                    this._toastMessageService.displayToastMessage(TOAST_STATE.success, response.responseMessage!, MAT_ICON.success);
                    this.dismissError();
                    this._router.navigateByUrl('/series');
                });
        });
    }

    addProduct(product: Material) {
        this.serieNumberToAdd = this.serieNumberToAdd + 1;
        const bookWithSeriNumber: BookWithSerienumber = {
            book: product,
            serieNumber: this.serieNumberToAdd
        };
        const index = this.products.indexOf(product);
        this.products.splice(index, 1);
        this.selectedBookList.push(bookWithSeriNumber);
    }

    deleteFromSerie(selectedBookList: BookWithSerienumber) {
        const index = this.selectedBookList.indexOf(selectedBookList);
        this.selectedBookList.splice(index, 1);
        this.products.push(selectedBookList.book!);
    }

    increaseSerieNumber(selectedBook: BookWithSerienumber) {
        const index = this.selectedBookList.indexOf(selectedBook);
        const i = index + 1;
        this.selectedBookList.forEach((selectedBookElement) => {
            if (this.selectedBookList[i].book?.id === selectedBookElement.book?.id) {
                selectedBookElement.serieNumber = i;
            }
        });
        this.selectedBookList = this.move(index, index + 1, this.selectedBookList);
        this.selectedBookList.forEach((selectedBookElement) => {
            if (selectedBook.book?.id === selectedBookElement.book?.id) {
                selectedBookElement.serieNumber = index + 2;
            }
        });
        console.log(this.selectedBookList);
        console.log(index + 2);
    }

    decreaseSerieNumber(selectedBook: BookWithSerienumber) {
        const index = this.selectedBookList.indexOf(selectedBook);
        const i = index - 1;
        this.selectedBookList.forEach((selectedBookElement) => {
            if (this.selectedBookList[i].book?.id === selectedBookElement.book?.id) {
                selectedBookElement.serieNumber = i + 2;
            }
        });
        this.selectedBookList = this.move(index, index - 1, this.selectedBookList);
        this.selectedBookList.forEach((selectedBookElement) => {
            if (selectedBook.book?.id === selectedBookElement.book?.id) {
                selectedBookElement.serieNumber = index;
            }
        });
        console.log(index - 2);
        console.log(this.selectedBookList);
    }

    move(from: number, to: number, selectedBook: BookWithSerienumber[]): BookWithSerienumber[] {
        const newArr = [...selectedBook];

        const item = newArr.splice(from, 1)[0];
        newArr.splice(to, 0, item);

        return newArr;
    }
}
