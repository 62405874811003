import { Component, Inject, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { ApiRequestService, MAT_ICON, TOAST_STATE, ToastmessageService, HttpError, TranslationService } from '@prekog/pw-common';
import { catchError, throwError } from 'rxjs';
import { HttpErrorResponse } from '@angular/common/http';
import { GetProductsForPackageRequest } from './getproductsForPackageRequest';
import { Package } from '../package';
import { map } from 'rxjs';
import { GetProductsForPackageResponse } from './getproductsforpackageresponse';
import { GetProductsResponse } from '../../products/getproductsresponse';
import { CdkDragDrop, moveItemInArray, transferArrayItem } from '@angular/cdk/drag-drop';
import { CreatePackageProductRequest } from '../addproductstopackage/createpackageproductrequest';
import { CreatePackageProductResponse } from '../addproductstopackage/createpackageproductresponse';
import { PackageProduct } from '../addproductstopackage/packageproduct';
import { DeletePackageProductRequest } from './deletepackageproductrequest';
import { DeletePackageProductResponse } from './deletepackageproductresponse';
import { Material } from '../../products/material';

@Component({
    selector: 'app-updatepackageproducts',
    templateUrl: './updatepackageproducts.component.html',
    styleUrls: ['./updatepackageproducts.component.scss']
})
export class UpdatepackageproductsComponent implements OnInit {
    getProductsForPackageUrl = '/admin/get-packageproduct';

    storeBaseUrl = '';

    updateableProducts: Material[] = [];

    products: Material[] = [];

    getProductsForPackageRequest: GetProductsForPackageRequest = {};

    package: Package = {};

    storeExistingProductsURI = '/store/get-products';

    createPackageProductRequest: CreatePackageProductRequest = {};

    createPackageProductUrl = '/admin/createpackageproduct';

    deletePackageProductUrl = '/admin/deletepackageproduct';

    deletePackageProductRequest: DeletePackageProductRequest = {};

    deletePackageProductResponse: DeletePackageProductResponse = {};

    filteredBooks: Material[] = [];



    constructor(
        private _router: Router,
        private _route: ActivatedRoute,
        private _toastMessageService: ToastmessageService,
        @Inject('STORE_BACKEND_URL') private _storeBaseUrl: string,
        private _apiRequestService: ApiRequestService,
        private _translateService: TranslationService
    ) {
        this.storeBaseUrl = _storeBaseUrl;
    }

    performFilter(filterBy: string): Material[] {
        filterBy = filterBy.toLocaleLowerCase();
        return this.products.filter(
            (publication: Material) =>
                publication.title?.toLocaleLowerCase().includes(filterBy) || publication.author?.toLocaleLowerCase().includes(filterBy) || publication.title?.toLocaleLowerCase().includes(filterBy)

        );
    }

    ngOnInit(): void {
        if (!sessionStorage.getItem('token')) {
            this._router.navigateByUrl('/');
        } else {
            this._route.paramMap.pipe(map(() => window.history.state)).subscribe((res: Package) => {
                //res contains "navigationId" variable as well, we dont need it
                this.package = {
                    packageName: res.packageName,
                    imageUrl: res.imageUrl,
                    id: res.id,
                    routerLink: res.routerLink
                };
                this.getProductsForPackageRequest = {
                    routerLink: res.routerLink
                };
            });

            this._apiRequestService
                .sendRequest<GetProductsForPackageRequest, GetProductsForPackageResponse>(
                    this.storeBaseUrl,
                    true,
                    this.getProductsForPackageRequest,
                    true,
                    this.getProductsForPackageUrl
                )
                .pipe(
                    catchError((error: HttpErrorResponse) => {
                        return throwError(() => {
                            const httpError: HttpError = error.error as HttpError;

                            this._toastMessageService.displayToastMessage(
                                TOAST_STATE.error,
                                this._translateService.translate('admin.unsuccessfulSave! ' + httpError.errorMessage),
                                MAT_ICON.error
                            );
                            this.dismissError();

                            this._router.navigateByUrl('/');
                        });
                    })
                )
                .subscribe((response) => {
                    this.updateableProducts = response.products as Material[];
                });
            this._apiRequestService
                .sendRequest(this.storeBaseUrl, true, {}, true, this.storeExistingProductsURI)
                .pipe(
                    catchError((error: HttpErrorResponse) => {
                        return throwError(() => {
                            const httpError: HttpError = error.error as HttpError;

                            this._toastMessageService.displayToastMessage(
                                TOAST_STATE.error,
                                this._translateService.translate('admin.unsuccessfulSave! ' + httpError.errorMessage),
                                MAT_ICON.error
                            );
                            this.dismissError();

                            this._router.navigateByUrl('/');
                        });
                    })
                )
                .subscribe((response) => {
                    const products = response as GetProductsResponse;
                    this.products = products.productList as Material[];
                });
        }
    }

    private dismissError(): void {
        setTimeout(() => {
            this._toastMessageService.dismissToast();
        }, 3000);
    }

    updateProduct(products: Material[]) {
        //TODO separate backend calls to different methods for clean code
        this.deletePackageProductRequest = {
            packageId: this.package.id
        };
        this._apiRequestService
            .sendRequest<DeletePackageProductRequest, DeletePackageProductResponse>(
                this.storeBaseUrl,
                true,
                this.deletePackageProductRequest,
                true,
                this.deletePackageProductUrl
            )
            .pipe(
                catchError((error: HttpErrorResponse) => {
                    return throwError(() => {
                        const httpError: HttpError = error.error as HttpError;

                        this._toastMessageService.displayToastMessage(
                            TOAST_STATE.error,
                            this._translateService.translate('admin.unsuccessfulSave! ' + httpError.errorMessage),
                            MAT_ICON.error
                        );
                        this.dismissError();

                        this._router.navigateByUrl('/');
                    });
                })
            )
            .subscribe((responseMessage) => {
                let packageProductList: PackageProduct[] = [];
                products.forEach((productsToPackage) => {
                    const packageProduct: PackageProduct = new PackageProduct();
                    packageProduct.productId = productsToPackage.id;
                    packageProduct.packageObject = this.package;
                    packageProductList.push(packageProduct);
                });
                this.createPackageProductRequest = {
                    packageProduct: packageProductList
                };
                this._apiRequestService
                    .sendRequest<CreatePackageProductRequest, CreatePackageProductResponse>(
                        this.storeBaseUrl,
                        true,
                        this.createPackageProductRequest,
                        true,
                        this.createPackageProductUrl
                    )
                    .pipe(
                        catchError((error: HttpErrorResponse) => {
                            return throwError(() => {
                                const httpError: HttpError = error.error as HttpError;

                                this._toastMessageService.displayToastMessage(
                                    TOAST_STATE.error,
                                    this._translateService.translate('admin.unsuccessfulSave! ' + httpError.errorMessage),
                                    MAT_ICON.error
                                );
                                this.dismissError();

                                this._router.navigateByUrl('/');
                            });
                        })
                    )
                    .subscribe((responseMessage) => {
                        this._toastMessageService.displayToastMessage(
                            TOAST_STATE.success,
                            responseMessage.responseMessage!,
                            MAT_ICON.success
                        );
                        this.dismissError();
                    });
            });
    }

    drop(event: CdkDragDrop<Material[]>) {
        if (event.previousContainer === event.container) {
            moveItemInArray(event.container.data, event.previousIndex, event.currentIndex);
        } else {
            transferArrayItem(event.previousContainer.data, event.container.data, event.previousIndex, event.currentIndex);
        }
    }
}
