import { Component, Inject } from '@angular/core';
import { HomeConfig } from '../home-config';
import { Router } from '@angular/router';
import { ApiRequestService, HttpError, MAT_ICON, TOAST_STATE, ToastmessageService, TranslationService } from '@prekog/pw-common';
import { HomeConfigResponse } from '../homeconfigresponse';
import { CdkDragDrop, moveItemInArray } from '@angular/cdk/drag-drop';
import { HomeConfigOrder } from './homeconfigorder';
import { UpdateHomeConfigOrderRequest } from './updatehomeconfigorderrequest';
import { UpdateBannerOrderRequest } from 'src/app/banners/orderbanners/updatebannerorderrequest';
import { UpdateHomeConfigOrderResponse } from './updatehomeconfigorderresponse';
import { catchError, throwError } from 'rxjs';
import { HttpErrorResponse } from '@angular/common/http';

@Component({
    selector: 'app-order-home-config',
    templateUrl: './order-home-config.component.html',
    styleUrls: ['./order-home-config.component.scss']
})
export class OrderHomeConfigComponent {
    getHomeConfigsUrl = '/websiteconfigurator/get-homeconfigs';

    homeConfigList: HomeConfig[] = [];

    configuratorBaseUrl = '';

    updateHomeConfigOrderUrl = '/admin/update-homeconfig-order';

    constructor(
        private _router: Router,
        private _toastMessageService: ToastmessageService,
        private _apiRequestService: ApiRequestService,
        @Inject('CONFIGURATOR_BASE_URL') private _configuratorBaseUrl: string,
        private _translateService: TranslationService
    ) {
        this.configuratorBaseUrl = _configuratorBaseUrl;
    }

    ngOnInit(): void {
        if (sessionStorage.getItem('token')) {
            this._apiRequestService
                .sendRequest<{}, HomeConfigResponse>(this.configuratorBaseUrl, true, {}, true, this.getHomeConfigsUrl)
                .subscribe((response) => {
                    this.homeConfigList = response.homeConfigList as HomeConfig[];
                    //this.homeConfigList = response.homeConfigList?.filter(homeConfig=>homeConfig.active) as HomeConfig[]
                });
        } else {
            this._router.navigateByUrl('/');
        }
    }

    drop(event: CdkDragDrop<string[]>) {
        moveItemInArray(this.homeConfigList, event.previousIndex, event.currentIndex);
    }

    updateHomeConfigOrder() {
        let homeConfigOrderList: HomeConfigOrder[] = [];

        this.homeConfigList.forEach((homeConfig, index) => {
            const homeConfigOrder = {
                id: homeConfig.id,
                orderNumber: index,
                active:homeConfig.active
            };
            homeConfigOrderList.push(homeConfigOrder);
        });
        const updateHomeConfigOrderRequest: UpdateHomeConfigOrderRequest = {
            homeConfigList: homeConfigOrderList
        };

        this._apiRequestService
            .sendRequest<UpdateHomeConfigOrderRequest, UpdateHomeConfigOrderResponse>(
                this.configuratorBaseUrl,
                true,
                updateHomeConfigOrderRequest,
                true,
                this.updateHomeConfigOrderUrl
            )
            .pipe(
                catchError((error: HttpErrorResponse) => {
                    return throwError(() => {
                        const httpError: HttpError = error.error as HttpError;

                        this._toastMessageService.displayToastMessage(
                            TOAST_STATE.error,
                            'Sikertelen módosítás' + httpError.errorMessage,
                            MAT_ICON.error
                        );
                        this.dismissError();
                    });
                })
            )
            .subscribe((response) => {
                this._toastMessageService.displayToastMessage(TOAST_STATE.success, response.responseMessage!, MAT_ICON.success);
                this.dismissError();
            });
    }

    private dismissError(): void {
        setTimeout(() => {
            this._toastMessageService.dismissToast();
        }, 3000);
    }

    onChangeActive(homeConfig: HomeConfig) {
        this.homeConfigList.forEach((homeConfigElement) => {
            if (homeConfigElement.component === homeConfig.component) {
                homeConfigElement.active = !homeConfig.active;
                console.log(homeConfigElement.active)
            }
        });
    }
}
