import { Component, Inject, Input, OnInit } from '@angular/core';
import { Serie } from '../serie';
import { AddProductToSerieRequest } from './addproducttoserierequest';
import { AddProductToSerieResponse } from './addproducttoserieresponse';
import { ActivatedRoute, Router } from '@angular/router';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ApiRequestService, HttpError, MAT_ICON, TOAST_STATE, ToastmessageService, TranslationService } from '@prekog/pw-common';
import { catchError, throwError } from 'rxjs';
import { GetProductsResponse } from '../../products/getproductsresponse';
import { HttpErrorResponse } from '@angular/common/http';
import { GetSerieRequest } from './getserierequest';
import { GetSerieResponse } from './getserieresponse';
import { BookWithSerienumber } from '../addserie/bookwithserienumber';
import { DeleteSerieProductRequest } from './deleteserieproductrequest';
import { BreakpointObserver, BreakpointState, Breakpoints } from '@angular/cdk/layout';
import { MatDialog } from '@angular/material/dialog';
import { Observable } from 'rxjs';
import { ConfirmdeleteproductfromseriepopupcomponentComponent } from './confirmdeleteproductfromseriepopupcomponent/confirmdeleteproductfromseriepopupcomponent.component';
import { deleteProductMatData } from './deleteproductmatdata';
import { moveItemInArray } from '@angular/cdk/drag-drop';
import { Material } from '../../products/material';

@Component({
    selector: 'app-addproductstoserie',
    templateUrl: './addproductstoserie.component.html',
    styleUrls: ['./addproductstoserie.component.scss']
})
export class AddproductstoserieComponent implements OnInit {
    serie: Serie = {};

    storeBaseUrl = '';

    products: Material[] = [];

    filteredBooks: Material[] = [];

    productsToChooseToUpdate: Material[] = [];

    addProductToSerieRequest: AddProductToSerieRequest = {};

    addProductToSerieResponse: AddProductToSerieResponse = {};

    serieNumberForm?: FormGroup;

    serieNumber: number = 0;

    selectedBook: Material = {};

    selectedBook2: Material = {};

    storeExistingProductsURI = '/store/get-products';

    routerLink = '';

    addProductsToSerieUrl = '/admin/addproductstoserie';

    getSerieRequest: GetSerieRequest = {};

    getSerieResponse: GetSerieResponse = {};

    getSerieUrl = '/admin/get-serie';

    @Input() serieName: string = '';

    @Input() routerLinkAddSerie: string = '';

    @Input()
    serieForm?: FormGroup;

    @Input()
    selectedBookList: BookWithSerienumber[] = [];

    @Input()
    filteredProduct: Material[] = [];

    @Input()
    existingProducts: BookWithSerienumber[] = [];

    productsForASerie: BookWithSerienumber[] = [];

    private _listFilter = '';

    get listFilter(): string {
        return this._listFilter;
    }
    set listFilter(value: string) {
        this._listFilter = value;
        this.filteredBooks = this.performFilter(value);
    }

    isExtraSmall: Observable<BreakpointState>;

    serieNumberToAdd = 0;

    constructor(
        public _router: Router,
        private _fb: FormBuilder,
        @Inject('STORE_BACKEND_URL') private _storeBaseUrl: string,
        private _apiRequestService: ApiRequestService,
        private _toastMessageService: ToastmessageService,
        private _translateService: TranslationService,
        private _route: ActivatedRoute,
        private readonly breakpointObserver: BreakpointObserver,
        private _matDialog: MatDialog
    ) {
        this.storeBaseUrl = _storeBaseUrl;
        this.createSerieNumberForm();
        this.isExtraSmall = this.breakpointObserver.observe(Breakpoints.XSmall);
    }

    ngOnInit(): void {
        if (sessionStorage.getItem('token')) {
            const lastSegmentOfUrl = window.location.href.split('/').pop();
            this.routerLink = lastSegmentOfUrl as string;

            this._apiRequestService.sendRequest(this.storeBaseUrl, true, {}, true, this.storeExistingProductsURI).subscribe((response) => {
                const products = response as GetProductsResponse;
                this.products = products.productList as Material[];
                this.productsToChooseToUpdate = products.productList as Material[];
                this.filteredBooks = this.productsToChooseToUpdate;
                this.getSerieRequest = {
                    routerLink: this.routerLink
                };
                this._apiRequestService
                    .sendRequest<GetSerieRequest, GetSerieResponse>(this.storeBaseUrl, true, this.getSerieRequest, true, this.getSerieUrl)
                    .subscribe((response) => {
                        this.serie = response.serie as Serie;
                        this.products.forEach((product) => {
                            if (product.serieId === this.serie.id) {
                                const index = this.productsToChooseToUpdate.indexOf(product);
                                this.productsToChooseToUpdate.splice(index, 1);
                                const existingBook = {
                                    book: product,
                                    serieNumber: product.serieNumber
                                };
                                this.productsForASerie.push(existingBook);
                                this.selectedBookList.push(existingBook);
                            }
                            this.selectedBookList.sort((a, b) => a.serieNumber! - b.serieNumber!);
                        });
                    });
            });
        } else {
            this._router.navigateByUrl('/');
        }
    }

    performFilter(filterBy: string): Material[] {
        filterBy = filterBy.toLocaleLowerCase();
        return this.products.filter(
            (publication: Material) =>
                publication.title?.toLocaleLowerCase().includes(filterBy) || publication.author?.toLocaleLowerCase().includes(filterBy)
        );
    }
    createSerieNumberForm() {
        this.serieNumberForm = this._fb.group({
            serieNumber: ['', [Validators.required]]
        });
    }

    collectSerieNumber() {
        this.serieNumber = this.serieNumberForm?.controls['serieNumber'].value as number;
    }

    private dismissError(): void {
        setTimeout(() => {
            this._toastMessageService.dismissToast();
        }, 3000);
    }

    addProduct(product: Material) {
        this.serieNumberToAdd = this.serieNumberToAdd + 1;
        const bookWithSeriNumber: BookWithSerienumber = {
            book: product,
            serieNumber: this.serieNumberToAdd
        };
        const index = this.products.indexOf(product);
        this.products.splice(index, 1);
        this.selectedBookList.push(bookWithSeriNumber);
    }

    addNewProduct() {
        const bookWithSeriNumber: BookWithSerienumber = {
            book: this.selectedBook2,
            serieNumber: this.serieNumberForm?.controls['serieNumber'].value as number
        };
        const index = this.productsToChooseToUpdate.indexOf(this.selectedBook);
        this.productsToChooseToUpdate.splice(index, 1);
        this.existingProducts.push(bookWithSeriNumber);
    }
    deleteFromExistingSerie(existingProduct: BookWithSerienumber) {
        const index = this.existingProducts.indexOf(existingProduct);
        this.existingProducts.splice(index, 1);
        this.productsToChooseToUpdate.push(existingProduct.book!);
    }
    deleteFromSerie(selectedBookList: BookWithSerienumber) {
        const index = this.selectedBookList.indexOf(selectedBookList);
        this.selectedBookList.splice(index, 1);
        this.products.push(selectedBookList.book!);
    }

    addBookToSerie(product: Material) {
        this.selectedBook = product;
        this.filteredProduct = [];
    }

    removeProductFromSerie(productId: number) {
        const deleteProductMatData: deleteProductMatData = {
            productId: productId,
            routerLink: this.routerLink
        };
        const popupDialog = this._matDialog.open(ConfirmdeleteproductfromseriepopupcomponentComponent, {
            data: deleteProductMatData,
            width: 'calc(100% - 50px)',
            maxWidth: '100vw',
            height: '80%'
        });
        const xsmallDialogSubscription = this.isExtraSmall.subscribe((size) => {
            if (size.matches) {
                popupDialog.updateSize('100vw', '100%');
            } else {
                popupDialog.updateSize('calc(60% - 50px)', '');
            }
        });

        popupDialog.afterClosed().subscribe(() => {
            xsmallDialogSubscription.unsubscribe();
        });
    }

    increaseSerieNumber(selectedBook: BookWithSerienumber) {
        const index = this.selectedBookList.indexOf(selectedBook);
        const i = index + 1;
        this.selectedBookList.forEach((selectedBookElement) => {
            if (this.selectedBookList[i].book?.id === selectedBookElement.book?.id) {
                selectedBookElement.serieNumber = i;
            }
        });
        this.selectedBookList = this.move(index, index + 1, this.selectedBookList);
        this.selectedBookList.forEach((selectedBookElement) => {
            if (selectedBook.book?.id === selectedBookElement.book?.id) {
                selectedBookElement.serieNumber = index + 2;
            }
        });
        console.log(this.selectedBookList);
        console.log(index + 2);
    }

    decreaseSerieNumber(selectedBook: BookWithSerienumber) {
        const index = this.selectedBookList.indexOf(selectedBook);
        const i = index - 1;
        this.selectedBookList.forEach((selectedBookElement) => {
            if (this.selectedBookList[i].book?.id === selectedBookElement.book?.id) {
                selectedBookElement.serieNumber = i + 2;
            }
        });
        this.selectedBookList = this.move(index, index - 1, this.selectedBookList);
        this.selectedBookList.forEach((selectedBookElement) => {
            if (selectedBook.book?.id === selectedBookElement.book?.id) {
                selectedBookElement.serieNumber = index;
            }
        });
        console.log(index - 2);
        console.log(this.selectedBookList);
    }

    move(from: number, to: number, selectedBook: BookWithSerienumber[]): BookWithSerienumber[] {
        const newArr = [...selectedBook];

        const item = newArr.splice(from, 1)[0];
        newArr.splice(to, 0, item);

        return newArr;
    }
}
