<div class="contact-content">
    <div class="back-button">
        <mat-icon routerLink="/onsaleperiod">arrow_back</mat-icon>
    </div>
    <div>
        <h3>AKCIÓ MÓDOSÍTÁSA</h3>
    </div>
    <div class="contact-form">
        <form [formGroup]="saleForm!" novalidate>
            <div class="field-container">
                <div class="field">
                    <span>Akció neve</span>
                    <div class="data">
                        <input
                            [(ngModel)]="onSalePeriod.name"
                            formControlName="onSaleName"
                            id="onSaleName"
                            class="form-control"
                            type="text"
                            required
                        />
                    </div>
                </div>
            </div>
            <div class="field-container">
                <div class="field">
                    <span>Akció kezdő dátuma</span>
                    <div class="data">
                        <input
                            [ngModel]="onSalePeriod.startDate | date : 'yyyy-MM-dd'"
                            (ngModel)="onSalePeriod.startDate = $any($event).target.valueAsDate"
                            formControlName="onSaleStartDate"
                            (change)="onChangeStartDate($event)"
                            id="onSaleStartDate"
                            class="form-control"
                            type="date"
                            placeholder="YYYY-MM-DD"
                            pattern="[0-9]{4}-[0-9]{2}-[0-9]{2}"
                            required
                        />
                    </div>
                </div>
            </div>
            <div class="field-container">
                <div class="field">
                    <span>Akció Befejező dátuma</span>
                    <div class="data">
                        <input
                            [ngModel]="onSalePeriod.endDate | date : 'yyyy-MM-dd'"
                            (ngModel)="onSalePeriod.endDate = $any($event).target.valueAsDate"
                            formControlName="onSaleEndDate"
                            (change)="onChangeEndDate($event)"
                            id="onSaleEndDate"
                            class="form-control"
                            type="date"
                            placeholder="YYYY-MM-DD"
                            pattern="[0-9]{4}-[0-9]{2}-[0-9]{2}"
                            required
                        />
                    </div>
                </div>
            </div>
        </form>
    </div>

    <h3>AKCIÓ ELEMEINEK MÓDOSÍTÁSA</h3>
    <div class="drag-and-drop-container">
        <div class="search-main-container">
            <div class="search-bar">
                <div class="search-tool">
                    <mat-icon aria-hidden="false" aria-label="keyboard_arrow_right icon" fontIcon="search"></mat-icon>
                    <input [(ngModel)]="listFilter" type="search" placeholder="Szerző, cím neve" />
                </div>
            </div>
            <div class="title">
                <h3>{{ onSalePeriod.name }} csomag tartalma</h3>
            </div>
        </div>
        <div class="drag-and-drop">
            <div class="products-container">
                <div class="products">
                    <div *ngFor="let product of filteredBooks" class="product">
                        {{ product.author + ': ' + product.title }}
                        <mat-icon (click)="addProductToOnSale(product)">add_circle_outline</mat-icon>
                    </div>
                </div>
            </div>
            <div class="title-mobile">
                <h3>{{ onSalePeriod.name }} csomag tartalma</h3>
            </div>
            <div class="products-container">
                <div class="products">
                    <div *ngFor="let product of updateableProducts" class="product">
                        {{ product.author + ': ' + product.title }}
                        <mat-icon (click)="deleteFromOnSaleProductList(product)">remove_circle_outline</mat-icon>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div [ngStyle]="{ 'margin-top': '8px' }" class="field-container">
        <div [ngStyle]="{ 'justify-content': 'center', 'margin-top': '8px', 'margin-bottom': '8px' }" class="field">
            <div [ngStyle]="{ 'justify-content': 'center' }" class="data">
                <button
                    (click)="updateOnSalePeriod()"
                    [ngStyle]="{ background: '#e8fcfd', border: '1px solid #0E8388', 'font-weight': 'bold', width: '100%' }"
                    [disabled]="!saleForm?.valid"
                >
                    AKCIÓ ELEMEINEK MÓDOSÍTÁSA
                </button>
            </div>
        </div>
    </div>
</div>
